import { defineStore } from 'pinia'

type PageStore = {
	loading: boolean,
	navOpen: boolean,
	toasts: []
}

export const usePageStore = defineStore('page', {
	state: () => ({
		loading: false,
		navOpen: false,
	} as PageStore),
	actions: {
		toggleNavState(state: boolean) {
			this.navOpen = state
		},
		setLoadingState(state: boolean) {
			this.loading = state
		}
	}
})
