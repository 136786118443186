<template>
	<div class="fixed inset-0 bg-black/80 background-blur-sm z-50 grid grid-cols-[minmax(auto,700px)] justify-center items-center transition-opacity p-4" :class="{ 'opacity-0 pointer-events-none': !open }" @click.self="closeFunc()">
		<div class="max-h-[90vh] bg-gray-800 rounded-md overflow-y-auto">
			<div class="flex items-center justify-between border-b border-gray-700 p-5">
				<h2 class="font-bold text-white text-xl">{{ title }}</h2>
				<button @click="closeFunc()" class="bg-gray-700 rounded-md p-2">
					<XMarkIcon class="w-6 h-6" />
				</button>
			</div>
			<div class="p-5">
				<slot />
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>

import { XMarkIcon } from '@heroicons/vue/24/solid'
import {onBeforeUnmount, onMounted} from 'vue'

const props = defineProps<{
	title: string,
	open: boolean,
	closeFunc: Function
}>()

const listener = (e: KeyboardEvent) => {
	if (e.key?.toLowerCase() === 'escape' && props.open) {
		props.closeFunc()
	}
}

onMounted(() => {
	window.addEventListener('keyup', listener)
})

onBeforeUnmount(() => {
	window.removeEventListener('keyup', listener)
})

</script>
