<template>
	<AppLayout>
		<RouterView />
	</AppLayout>

	<div id="modals">
		<Modal :open="userStore.logoutOpen" :close-func="userStore.closeLogout" title="Confirm Logout">
			<LogoutModal />
		</Modal>
	</div>
</template>

<script setup lang="ts">

import AppLayout from './layouts/AppLayout.vue'
import Modal from './components/Modal.vue'
import LogoutModal from './components/modals/LogoutModal.vue'
import { useUserStore } from './stores'

const userStore = useUserStore()

</script>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;

.custom-scrollbar {
	--scrollbar-foreground: theme('colors.gray.700');
	--scrollbar-background: transparent;
	/* Foreground, Background */
	scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background);
}
.custom-scrollbar::-webkit-scrollbar {
	width: 10px; /* Mostly for vertical scrollbars */
	height: 10px; /* Mostly for horizontal scrollbars */
}
.custom-scrollbar::-webkit-scrollbar-thumb { /* Foreground */
	background: var(--scrollbar-foreground);
	border-radius: 10px;
}
.custom-scrollbar::-webkit-scrollbar-track { /* Background */
	background: var(--scrollbar-background);
}
</style>
