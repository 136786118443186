<template>
	<div class="bg-gray-900/80 backdrop-blur absolute inset-0 grid items-center justify-center transition-opacity" :class="{ 'opacity-0 pointer-events-none': !pageStore.loading }">
		<div>
			<CloudIcon class="w-10 h-10 mx-auto mb-4 text-green-500 animate-pulse" />
			<div class="font-bold text-xl text-white text-center">Loading</div>
		</div>
	</div>
</template>

<script lang="ts" setup>

import { CloudIcon } from '@heroicons/vue/24/solid'
import { usePageStore } from '../stores'
const pageStore = usePageStore()

</script>
