import {defineStore} from 'pinia'
import {Notification, NotificationVariant} from '../types/Notification'
import {authPostLogout, getAuthMe, MeResponseExtended} from "../api";

type UserStore = {
	loggedIn: boolean,
	role: string,
	email: string,
	notifications: Notification[],
	logoutOpen: boolean,
	stateRefreshed: boolean
	user?: MeResponseExtended
	showAllNotifications: boolean
}

export const useUserStore = defineStore('user', {
	state: () => ({
		loggedIn: false,
		role: '',
		email: '',
		notifications: [],
		logoutOpen: false,
		stateRefreshed: false,
		user: undefined,
		emptyIpWhitelist: true,
		showAllNotifications: false
	} as UserStore),
	getters: {
		hasEmptyIpWhitelist(): boolean {
			return this.user?.empty_ip_whitelist ?? false
		},
		userId(): string {
			return this.user?.id || ''
		},
		avatarURL(): string {
			if (this.user?.discord_id) {
				return `https://cdn.discordapp.com/avatars/${this.user.discord_id}/${this.user.avatar_hash}.png`
			}
			return '/img/logo.png'
		},
		unreadNotificationsCount(): number {
			return this.notifications.filter(n => !n.readAt).length
		}
	},
	actions: {
		toggleShowAllNotifications() {
			this.showAllNotifications = !this.showAllNotifications
		},
		setShowAllNotifications(value: boolean) {
			this.showAllNotifications = value
		},
		setHasEmptyIpWhitelist(value: boolean) {
			if (!this.user) return
			this.user.empty_ip_whitelist = value
		},
		setStateRefreshed(stateRefreshed: boolean) {
			this.stateRefreshed = stateRefreshed
		},
		clear() {
			this.loggedIn = false
			this.role = ''
			this.email = ''
			this.notifications = []
			this.logoutOpen = false
			this.stateRefreshed = false
			this.user = undefined
		},
		openLogout() {
			this.logoutOpen = true
		},
		closeLogout() {
			this.logoutOpen = false
		},
		async logout(all: boolean = false) {
			await authPostLogout(all).catch(console.error)
			this.clear()
		},
		markNotificationAsSeen(id: string) {
			const notification = this.notifications.findIndex(n => n.id === id)
			if (notification === -1) return
			this.notifications[notification].readAt = new Date()
		},
		markAllNotificationsAsSeen() {
			this.notifications.forEach(n => n.readAt = new Date())
		},
		setUser(user: MeResponseExtended) {
			this.user = user
			this.email = user.email
			this.role = user.rank_name
			this.notifications = user.notifications?.map(v => ({
				id: v.id,
				title: 'Notification',
				text: v.message,
				time: new Date(v.created_at),
				readAt: v.read_at ? new Date(v.read_at) : undefined,
				variants: v.type as NotificationVariant[]
			})) || []
		},
		setIsLoggedIn(isLoggedIn: boolean) {
			this.loggedIn = isLoggedIn
		},
		async refreshStore() {
			this.setStateRefreshed(true)
			await getAuthMe(true).then(res => {
				if (res.status === 200) {
					this.setUser(<MeResponseExtended>res.data)
					this.setIsLoggedIn(true)
				} else if (res.status === 401) {
					this.clear()
				} else {
					this.clear()
				}
			})

		}
	}
})
