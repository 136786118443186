import { defineStore } from 'pinia'
import {Notification, NotificationVariant} from '../types/Notification'
import {authPostLogout, getAuthMe, UserReturnedByTheMeEndpoint} from "../api";

type LoginStore = {
	email: string
	password: string
	rememberMe: boolean
	isInitialized: boolean
}

export const useLoginStore = defineStore('login', {
	state: () => ({
		email: '',
		password: '',
		rememberMe: false,
		isInitialized: false,
	} as LoginStore),
	actions: {
		setup(email: string, password: string, rememberMe: boolean) {
			this.isInitialized = true
			this.email = email
			this.password = password
			this.rememberMe = rememberMe
		}
	}
})
