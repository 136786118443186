import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import {usePageStore} from './stores'

const routes: RouteRecordRaw[] = [
	{
		name: 'home',
		path: '/',
		component: () => import('./pages/home.vue'),
		meta: {
			layout: 'dashboard',
			dynamic: true,
		}
	},
	{
		name: 'overview',
		path: '/products',
		component: () => import('./pages/products/overview.vue'),
		meta: {
			layout: 'dashboard',
			dynamic: true,
		}
	},
	{
		name: 'datacenter',
		path: '/products/datacenter',
		component: () => import('./pages/products/datacenter.vue'),
		meta: {
			layout: 'dashboard',
			dynamic: true,
		}
	},
	{
		name: 'datacenter-product',
		path: '/products/datacenter/:id',
		component: () => import('./pages/products/datacenterProduct.vue'),
		meta: {
			layout: 'dashboard',
			dynamic: true,
		}
	},
	{
		name: 'residential',
		path: '/products/residential',
		component: () => import('./pages/products/residential.vue'),
		meta: {
			layout: 'dashboard',
			dynamic: true,
		}
	},
	{
		name: 'residential-product',
		path: '/products/residential/:id',
		component: () => import('./pages/products/residentialProduct.vue'),
		meta: {
			layout: 'dashboard',
			dynamic: true,
		}
	},
	{
		name: 'gmail',
		path: '/products/gmail',
		component: () => import('./pages/products/gmail.vue'),
		meta: {
			layout: 'dashboard',
			dynamic: true,
		}
	},
	{
		name: 'gmail-product',
		path: '/products/gmail/:id',
		component: () => import('./pages/products/gmailProduct.vue'),
		meta: {
			layout: 'dashboard',
			dynamic: true,
		}
	},
	{
		name: 'snkrs',
		path: '/products/snkrs',
		component: () => import('./pages/products/snkrs.vue'),
		meta: {
			layout: 'dashboard',
			dynamic: true,
		}
	},
	{

		name: 'snkrs-product',
		path: '/products/snkrs/:id',
		component: () => import('./pages/products/snkrsProduct.vue'),
		meta: {
			layout: 'dashboard',
			dynamic: true,
		}
	},
	{
		name: 'user',
		path: '/user',
		component: () => import('./pages/user.vue'),
		redirect: '/user/settings',
		meta: {
			layout: 'dashboard'
		},
		children: [
			{
				name: 'settings',
				path: '/user/settings',
				component: () => import('./pages/user/settings.vue'),
				meta: {
					name: 'User Settings',
					dynamic: false,
				},
			},
			{
				name: 'notifications',
				path: '/user/notifications/:page?',
				component: () => import('./pages/user/notifications.vue'),
				meta: {
					name: 'Your Notifications',
					paginated: true,
					dynamic: true,
				},
			},
			{
				name: 'successes',
				path: '/user/successes/:page?',
				component: () => import('./pages/user/successes.vue'),
				meta: {
					name: 'Success History',
					paginated: true,
					dynamic: true,
				},
			},
			{
				name: 'billing',
				path: '/user/billing/:page?',
				component: () => import('./pages/user/billing.vue'),
				meta: {
					name: 'Invoices / Payments',
					paginated: true,
					dynamic: true,
				},
			},
			{
				name: 'ip-whitelist',
				path: '/user/ip-whitelist',
				component: () => import('./pages/user/ipWhitelist.vue'),
				meta: {
					name: 'ISP IP Whitelist',
					paginated: false,
					dynamic: true,
				},
			},
		]
	},
	{
		name: 'logoutEverywhere',
		path: '/logout-everywhere',
		component: () => import('./pages/logoutEverywhere.vue'),
		meta: {
			layout: 'dashboard',
			dynamic: false,
		}
	},
	{
		name: 'login',
		path: '/login',
		component: () => import('./pages/auth/login.vue'),
		meta: {
			layout: 'auth',
			dynamic: false,
		}
	},
	{
		name: 'register',
		path: '/register',
		component: () => import('./pages/auth/register.vue'),
		meta: {
			layout: 'auth',
			dynamic: false,
		}
	},
	{
		name: 'request-password-reset',
		path: '/request-password-reset',
		component: () => import('./pages/auth/requestPasswordReset.vue'),
		meta: {
			layout: 'auth',
			dynamic: false,
		}
	},
	{
		name: 'reset-password',
		path: '/reset-password',
		component: () => import('./pages/auth/resetPassword.vue'),
		meta: {
			layout: 'auth',
			dynamic: false,
		}
	},
	{
		name: 'activate-account',
		path: '/activate-account',
		component: () => import('./pages/auth/activateAccount.vue'),
		meta: {
			layout: 'auth',
			dynamic: false,
		}
	},
	{
		name: 'login-2fa',
		path: '/login/2fa',
		component: () => import('./pages/auth/two-factor.vue'),
		meta: {
			layout: 'auth',
			dynamic: false,
		}
	},
	{
		name: 'login-discord',
		path: '/login/discord',
		component: () => import('./pages/auth/loginDiscord.vue'),
		meta: {
			layout: 'auth',
			dynamic: false,
		}
	},
	{
		name: '404',
		path: '/:pathMatch(.*)*',
		component: () => import('./pages/404.vue'),
		meta: {
			layout: 'dashboard',
			dynamic: false,
		}
	},
]

const router = createRouter({
	history: createWebHistory(),
	routes,
})

router.beforeEach((to, from, next) => {
	const pageStore = usePageStore()
	if (to.meta.dynamic) {
		pageStore.setLoadingState(false)
		pageStore.toggleNavState(false)
		next()
	} else {
		pageStore.setLoadingState(false)
		pageStore.toggleNavState(false)
		next()
	}
})

export default router
