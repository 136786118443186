<template>
	<component :is="layout">
		<slot />
	</component>
</template>

<script lang="ts" setup>

import { useRoute } from 'vue-router'
import { markRaw, watch, ref } from 'vue'
import DefaultLayout from  './default.vue'
import AuthLayout from './auth.vue'
import DashboardLayout from './dashboard.vue'

const route = useRoute()

const layoutName = route.meta.layout || 'default'
// @ts-ignore
const layout = ref()
watch(
	() => route.meta?.layout as string | undefined,
	async (metaLayout) => {
		switch (metaLayout) {
			default:
				layout.value = layout.value = markRaw(DefaultLayout)
				break
			case "auth":
				layout.value = layout.value = markRaw(AuthLayout)
				break
			case "dashboard":
				layout.value = layout.value = markRaw(DashboardLayout)
				break
		}
	},
	{ immediate: true }
)

</script>
