<template>
	<div>
		<Sidebar/>

		<div class="h-screen grid grid-rows-[auto_1fr_auto] lg:pl-[340px] transition-transform lg:translate-x-0"
			 :class="{ 'translate-x-[340px]': pageStore.navOpen }">
			<Topbar/>

			<div class="relative pt-12">
				<RouterView v-slot="{ Component, route }">
					<Transition name="fade">
						<div class="h-full">
							<component :is="Component" :key="route.path"/>
						</div>
					</Transition>
				</RouterView>

				<Loader/>
			</div>

			<Footer/>
		</div>
	</div>
</template>

<script lang="ts" setup>

import Footer from '../components/layout/Footer.vue'
import Sidebar from '../components/layout/Sidebar.vue'
import Topbar from '../components/layout/Topbar.vue'
import Loader from '../components/Loader.vue'
import {usePageStore, useUserStore} from '../stores'
import {useRouter} from "vue-router";
import {onBeforeMount, onMounted} from "vue";

const pageStore = usePageStore()

const router = useRouter()
const userStore = useUserStore()

async function checkAuth() {
	if (userStore.loggedIn) {
		return
	} else if (!userStore.stateRefreshed) {
		await userStore.refreshStore()
		if (userStore.loggedIn) {
			return
		}
	}

	await router.push('/login')
}

onBeforeMount(async () => {
	await checkAuth()
	pageStore.setLoadingState(false)
})

</script>

<style lang="scss">

.fade-enter-active,
.fade-leave-active {
	@apply transition-all duration-200;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
	position: absolute;
	transform: translateY(15px);
	left: 0;
	right: 0;
	top: 0;
}

</style>
