<template>
	<nav
		class="bg-gray-900 fixed w-[340px] top-0 lg:left-0 bottom-0 overflow-y-auto custom-scrollbar transition-all z-50"
		:class="pageStore.navOpen ? 'left-0' : '-left-full'">
		<div class="flex items-center justify-between">
			<RouterLink to="/" class="flex items-center p-5">
				<img src="/img/logo.png" class="h-16 w-16" alt="ProxyHeaven Logo">
				<span class="font-bold text-xl text-white ml-2">ProxyHeaven</span>
			</RouterLink>
			<button class="p-5 text-red-400 lg:hidden" @click="pageStore.toggleNavState(false)">
				<XMarkIcon class="w-6 h-6"/>
			</button>
		</div>

		<section class="pb-6">
			<NavItem to="/" :icon="CloudIcon" exact>Overview</NavItem>
		</section>

		<section class="pb-6">
			<div class="font-bold text-sm text-gray-600 px-6 mb-2">Products</div>

			<NavItem to="/products" exact :icon="ChartBarIcon">Overview</NavItem>
			<NavItem to="/products/datacenter" :icon="ServerStackIcon">Datacenter</NavItem>
			<NavItem to="/products/residential" :icon="HomeIcon">Residential</NavItem>
			<NavItem to="/products/gmail" :icon="EnvelopeIcon">Gmails</NavItem>
			<NavItem to="/products/snkrs" :icon="SnkrsIcon">Snkrs</NavItem>
			<NavItem @click="openHostingHeavenModal" :button="true" :icon="ServerIcon">
				Servers <span class="ml-auto bg-green-800 rounded-md text-sm px-2 text-green-500 uppercase">New</span>
			</NavItem>
			<NavItem @click="openEliteHeavenModal" :button="true" :icon="ChevronDoubleUpIcon"
					 v-if="userStore.role.toLowerCase() === 'user'">
				EliteHeaven <span
				class="ml-auto bg-green-800 rounded-md text-sm px-2 text-green-500 uppercase">New</span>
			</NavItem>
		</section>

		<section class="pb-6">
			<div class="font-bold text-sm text-gray-600 px-6 mb-2">Your Account</div>

			<NavItem to="/user/settings" :icon="Cog6ToothIcon">
				<div class="relative">
					Settings
					<span v-if="userStore.hasEmptyIpWhitelist"
						  class="count bg-red-600 text-white font-bold px-1.5 rounded-full absolute -top-1 -left-5 text-xs">!</span>
				</div>
			</NavItem>
			<NavItem to="/user/notifications" :icon="BellAlertIcon">Notifications <span
				class="ml-auto bg-gray-800 rounded-md text-sm px-2 text-gray-500">{{
					userStore.unreadNotificationsCount
				}}</span></NavItem>
			<NavItem to="/user/billing" :icon="ReceiptPercentIcon">Billing</NavItem>
			<NavItem button @click="userStore.openLogout()" :icon="ArrowRightOnRectangleIcon">Logout</NavItem>
		</section>
	</nav>
	<Teleport to="#modals">
		<Modal title="Redirecting to HostingHeaven" :close-func="closeHostingHeavenModal"
			   :open="hostingHeavenModalOpen">
			<div>
				Need <span class="font-semibold">powerful, reliable & cost-effective</span> servers for the ultimate
				botting experience? <br/>
				<a href="https://discord.gg/n548cSxruA" target="_blank" class="font-semibold text-white">Click here</a>
				&
				learn more about HostingHeaven! Exclusive deals for the Heaven community. <br/>
				Don't miss out!
			</div>
			<div class="font-semibold mt-2 text-white">
				<a href="https://discord.gg/n548cSxruA" target="_blank">Learn More</a>
			</div>
		</Modal>
	</Teleport>
	<Teleport to="#modals">
		<Modal title="EliteHeaven Application" :close-func="closeEliteHeavenModal"
			   :open="eliteHeavenModalOpen">
			<div>
				<p class="font-bold">What is EliteHeaven?</p>
				<p>
					EliteHeaven provides access to an exclusive set of Elite and Godlike plans only available to our
					Elite family EliteHeaven. These plans are limited to only our very best botters and they are closely
					monitored and maintained daily for the best possible performance on all sites.
				</p>
			</div>
			<div class="flex flex-row justify-between mt-2 border-t-2 border-gray-600">
				<div class="font-semibold mt-2 text-white">
					<a href="https://discord.gg/U5cy7zFYsd" target="_blank">Learn More</a>
				</div>
				<div class="font-semibold mt-2 text-white">
					<a href="https://forms.gle/XmxkeHNvDfZU6pkr6" target="_blank">Apply Here</a>
				</div>
			</div>
		</Modal>
	</Teleport>
</template>
<script lang="ts" setup>

import NavItem from './NavItem.vue'
import {
	ArrowRightOnRectangleIcon,
	BellAlertIcon,
	ChartBarIcon,
	ChevronDoubleUpIcon,
	CloudIcon,
	Cog6ToothIcon,
	EnvelopeIcon,
	HomeIcon,
	ReceiptPercentIcon,
	ServerIcon,
	ServerStackIcon,
	XMarkIcon
} from '@heroicons/vue/24/outline'
import SnkrsIcon from '../icons/Snkrs.vue'
import {usePageStore, useUserStore} from '../../stores'
import {ref} from "vue";
import Modal from "../../components/Modal.vue";

const userStore = useUserStore()
const pageStore = usePageStore()

const hostingHeavenModalOpen = ref(false)

const openHostingHeavenModal = () => {
	hostingHeavenModalOpen.value = true
}

const closeHostingHeavenModal = () => {
	hostingHeavenModalOpen.value = false
}


const eliteHeavenModalOpen = ref(false)

const openEliteHeavenModal = () => {
	eliteHeavenModalOpen.value = true
}

const closeEliteHeavenModal = () => {
	eliteHeavenModalOpen.value = false
}


</script>
