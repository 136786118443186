<template>
	<nav class="bg-gray-850 shadow-lg">
		<div class="container mx-auto flex">
			<button class="p-3 rounded-md bg-gray-800 lg:hidden my-3 relative"
					@click="pageStore.toggleNavState(!pageStore.navOpen)">
				<Bars3BottomLeftIcon class="w-6 h-6"/>
				<span v-if="userStore.hasEmptyIpWhitelist"
					  class="count bg-red-600 text-white font-bold px-1.5 rounded-full absolute top-1 right-1 font-bold text-xs">!</span>
			</button>

			<RouterLink to="/user/notifications"
						class="ml-auto px-6 flex items-center justify-center text-gray-500 relative transition-colors hover:text-white hover:bg-gray-800/50"
						active-class="bg-gray-800/50">
				<span class="count bg-red-600 text-white text-xs font-bold px-1.5 rounded-full absolute top-4 left-4"
					  v-if="userStore.unreadNotificationsCount > 0">{{ userStore.unreadNotificationsCount }}</span>
				<BellAlertIcon class="w-8 h-8"/>
			</RouterLink>

			<div class="relative" v-click-away="closeDrop">
				<button class="rounded-md p-5 flex items-center transition-colors hover:bg-gray-800/50"
						:class="dropdown ? 'bg-gray-800/50' : ''" @click="dropdown = !dropdown">
					<div class="text-right mr-3 hidden lg:block">
						<div class="font-bold text-lg text-white">{{ userStore.email }}</div>
						<div class="font-bold text-sm uppercase text-blue-500">{{ userStore.role }}</div>
					</div>
					<img :src="userStore.avatarURL" class="rounded-full w-10 h-10 bg-gray-800" alt="Profile Picture"/>
				</button>

				<div
					class="absolute top-full right-0 bg-gray-800 border border-gray-700 rounded-md w-max p-2 shadow-xl z-50 transition-all transform text-right"
					:class="{ 'opacity-0 pointer-events-none -translate-y-2': !dropdown }">
					<ul>
						<li>
							<RouterLink to="/user/settings"
										class="block py-2 px-5 rounded-md font-bold transition-colors hover:bg-gray-700 hover:text-white"
										href="#">Settings
							</RouterLink>
						</li>
						<li><a
							class="block py-2 px-5 rounded-md font-bold transition-colors hover:bg-gray-700 hover:text-white"
							href="javascript:void(0)" @click="userStore.openLogout()">Logout</a></li>
						<li>
							<RouterLink to="/logout-everywhere"
										class="block py-2 px-5 rounded-md font-bold transition-colors hover:bg-gray-700 hover:text-white">
								Logout Everywhere
							</RouterLink>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</nav>
</template>

<script lang="ts" setup>

import {ref} from 'vue'
import {BellAlertIcon, Bars3BottomLeftIcon} from '@heroicons/vue/24/outline'
import {usePageStore, useUserStore} from '../../stores'

const userStore = useUserStore()
const pageStore = usePageStore()

const dropdown = ref(false)
const closeDrop = () => {
	dropdown.value = false
}

</script>
