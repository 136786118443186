import {createApp} from 'vue'
import {createPinia} from 'pinia'
import VueClickAwayPlugin from 'vue3-click-away'
import App from './App.vue'
import router from './router'
import * as api from './api'
import {useUserStore} from "./stores";
import * as Sentry from "@sentry/vue";

import Toast, {PluginOptions, POSITION} from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

api.defaults.headers = {}
api.defaults.baseUrl = import.meta.env.VITE_API_URL
// @ts-ignore
api.defaults.fetch = async (input: RequestInfo, init?: (RequestInit | undefined)) => {
	// eslint-disable-next-line no-useless-catch
	try {

		const response = await fetch(input, {
			...init,
			credentials: "include",
			// @ts-ignore
			headers: {
				...api.defaults.headers,
				...(init && init.headers),
			},
		})
		if (response.status === 401) {
			useUserStore().logout()
			return response
		}
		return response
	} catch (e) {
		throw e
	}
}

const options: PluginOptions = {
	position: POSITION.TOP_CENTER,
	timeout: 3500,
	closeOnClick: true,
	pauseOnFocusLoss: false,
	pauseOnHover: false,
	draggable: false,
	draggablePercent: 0.6,
	showCloseButtonOnHover: false,
	hideProgressBar: true,
	closeButton: "button",
	icon: false,
	rtl: false
}

const app = createApp(App)
	.use(createPinia())
	.use(VueClickAwayPlugin)
	.use(Toast, options)


Sentry.init({
	app,
	dsn: "https://5db50e0cf4eb42ccae8bcd3b8c2bf495@o428586.ingest.sentry.io/4505081860194304",
	integrations: [
		new Sentry.BrowserTracing({
			routingInstrumentation: Sentry.vueRouterInstrumentation(router),
		}),
		new Sentry.Replay(),
	],

	tracesSampleRate: 0.05,

	// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ["localhost", /^https:\/\/api\.proxyheaven\.io/],
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});

app.use(router)
	.mount('#app')
