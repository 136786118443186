<template>
	<div class="h-screen grid grid-rows-[1fr_auto]">
		<div class="relative">
			<RouterView v-slot="{ Component, route }">
				<Transition name="fade">
					<div class="h-full">
						<component :is="Component" :key="route.path" />
					</div>
				</Transition>
			</RouterView>

			<Loader />
		</div>

		<Footer />
	</div>
</template>

<script lang="ts" setup>

import Footer from '../components/layout/Footer.vue'
import Sidebar from '../components/layout/Sidebar.vue'
import Topbar from '../components/layout/Topbar.vue'
import Loader from '../components/Loader.vue'
import {useRouter} from "vue-router";
import {useUserStore} from "../stores";

const router = useRouter()
const userStore = useUserStore()


async function checkAuth() {
	if (userStore.loggedIn) {
		await router.push('/')
		return
	} else if (!userStore.stateRefreshed) {
		await userStore.refreshStore()
		if (userStore.loggedIn) {
			await router.push('/')
			return
		}
	}
}

if (router.currentRoute.value.name === 'login') {
	checkAuth()
}

</script>

<style lang="scss">

.fade-enter-active,
.fade-leave-active {
	@apply transition-all duration-200;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
	position: absolute;
	transform: translateY(15px);
	left: 0;
	right: 0;
	top: 0;
}

</style>
