<template>
	<div class="font-bold text-lg lg:p-6 text-center">
		<p class="mb-3">Please click to confirm you want to logout.</p>
		<button @click.prevent="logout" class="rounded-full border-2 border-red-500 py-3 px-5 font-bold text-white inline-flex items-center transition-colors hover:border-red-300">
			<ExclamationCircleIcon class="w-6 h-6 mr-2" />
			<span>Logout</span>
		</button>
	</div>
</template>

<script lang="ts" setup>

import { ExclamationCircleIcon } from '@heroicons/vue/20/solid'
import {useUserStore} from "../../stores";
import {useRouter} from "vue-router";
import {useToast} from "vue-toastification";
const userStore = useUserStore()
const router = useRouter()
const toast = useToast()

async function logout() {
	await userStore.logout(false)
	toast.success('You have been logged out!')
	await router.push('/login')
}

</script>
