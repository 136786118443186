<template>
	<button v-if="button" class="relative flex items-center px-6 py-3 font-bold text-lg transition-colors hover:text-white hover:bg-gray-800/50 text-gray-500 w-full" type="button">
		<span class="bg-green-500 absolute left-0 top-0 bottom-0 w-1.5 rounded-r-md my-auto transition-all" :class="!isActive(href) ? 'h-0' : 'h-full'"></span>
		<Component :is="icon" class="w-6 h-6 mr-3 transition-colors text-gray-600" />
		<slot />
	</button>
	<RouterLink
		v-else
		v-bind="$props"
		:to="props.to"
		custom
		v-slot="{ href, navigate, route }"
	>
		<a
			:href="href"
			@click="navigate"
		    class="relative flex items-center px-6 py-3 font-bold text-lg transition-colors hover:text-white hover:bg-gray-800/50"
			:class="isActive(href) ? 'text-white' : 'text-gray-500'"
		>
			<span class="bg-green-500 absolute left-0 top-0 bottom-0 w-1.5 rounded-r-md my-auto transition-all" :class="!isActive(href) ? 'h-0' : 'h-full'"></span>
			<Component :is="icon" class="w-6 h-6 mr-3 transition-colors" :class="isActive(href) ? 'text-green-500' : 'text-gray-600'" />

			<slot />
		</a>
	</RouterLink>
</template>

<script lang="ts" setup>

import {RouterLink, useLink} from 'vue-router'
import { FunctionalComponent } from 'vue'

const props = defineProps({
	// @ts-ignore
	...RouterLink.props,
	exact: Boolean,
	button: {
		type: Boolean,
		required: false,
		default: () => false,
	},
	icon: {},
})

const isActive = (href: string) => {
	if (props.exact === true) {
		return document.location.pathname === href
	} else {
		return document.location.pathname.startsWith(href)
	}
}

</script>
