/**
 * Stripe API Spec
 * 1.0.0
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
import * as Oazapfts from "oazapfts/lib/runtime";
import * as QS from "oazapfts/lib/runtime/query";
export const defaults: Oazapfts.RequestOpts = {
    baseUrl: "/",
};
const oazapfts = Oazapfts.runtime(defaults);
export const servers = {};
export type UserReturnedByTheMeEndpoint = {
    id: string;
    /** The email of the user */
    email: string;
    rank_name: string;
    /** The score of the user */
    score: number;
    /** Whether the user is active or not */
    active: boolean;
    /** The discord tag of the user */
    discord_tag?: string;
    /** The discord ID of the user */
    discord_id?: string;
    /** The avatar hash of the user */
    avatar_hash?: string;
    /** Whether the user has fully setup 2FA or not */
    two_factor_fully_setup?: boolean;
    /** The permissions of the user */
    permissions?: string[];
};
export type Notification = {
    id: string;
    message: string;
    link_to?: string;
    created_at: string;
    "type": ("priority" | "warning" | "info")[];
    read_at?: string;
};
export type MeResponseExtended = {
    id: string;
    /** The email of the user */
    email: string;
    rank_name: string;
    /** The score of the user */
    score: number;
    /** Whether the user is active or not */
    active: boolean;
    /** The discord tag of the user */
    discord_tag?: string;
    /** The discord ID of the user */
    discord_id?: string;
    /** The avatar hash of the user */
    avatar_hash?: string;
    /** Whether the user has fully setup 2FA or not */
    two_factor_fully_setup?: boolean;
    notifications?: Notification[];
    customer_id?: string;
    empty_ip_whitelist: boolean;
};
export type Error = {
    /** The error message. */
    message: string;
};
export type LoginBody = {
    /** The email of the user */
    email: string;
    /** The password of the user */
    password: string;
    /** Whether the user should be remembered or not (generates cookies which last for longer than the browser session)
     */
    remember_me?: boolean;
    /** Whether the user should be trusted on this device or not (generates cookies which last for longer than
    the browser session). Only needed for 2fa protected users.
     */
    trust_this_device?: boolean;
    /** The two factor code of the user */
    two_factor_code?: string;
    /** The two factor backup code of the user */
    two_factor_backup_code?: string;
};
export type ShopActivateBody = {
    /** The password of the user */
    password: string;
    /** The email of the user */
    email: string;
    /** The activation key sent to the user for activating the account */
    key: string;
};
export type RegisterActivateBody = {
    key: string;
    /** The email of the user */
    email: string;
    /** The password of the user */
    password: string;
};
export type RegisterBody = {
    /** The email of the user */
    email: string;
    /** The password of the user */
    password: string;
};
export type RegisterResponse = {
    /** The ID of the newly created user */
    user_id: string;
};
export type ResendRegistrationKeyBody = {
    /** The id of the user */
    user_id: string;
};
export type ResendRegistrationKeyResponseBody = {
    next_allowed_attempt_at: string;
};
export type RequestPasswordResetBody = {
    /** The email of the user */
    email: string;
};
export type ResetPasswordBody = {
    /** The email of the user */
    email: string;
    token: string;
    /** The new password of the user */
    new_password: string;
};
export type BlockFlagType = "godlike";
export type Block = {
    /** The name of the block. */
    name: string;
    /** Whether the block is currently enforced or not. Unblocked = block is not enforced. */
    is_unblocked: boolean;
    /** The flags of the block. */
    flags: BlockFlagType[];
};
export type UnblockBody = {
    duration: string;
};
export type Discount = {
    /** The discount id. */
    id: string;
    /** The name of the discount */
    name: string;
    /** The percentage of the discount */
    percentage: number;
};
export type CreateDiscount = {
    /** The name of the discount */
    name: string;
    /** The percentage of the discount */
    percentage: number;
    restricted_user_id?: string;
};
export type UpdatableDiscount = {
    /** The percentage of the discount */
    percentage: number;
};
export type CalculateDiscountBody = {
    /** The discount code. */
    discount_code: string;
    /** The price of the extension. */
    price: number;
};
export type CalculatedDiscount = {
    /** The original price of the extension. */
    original_price: number;
    /** The discounted price of the extension. */
    discounted_price: number;
    /** The difference between the original price and the discounted price. */
    price_difference: number;
    /** The difference between the original price and the discounted price in percentage. */
    price_difference_percentage: number;
    /** The name of the discount. */
    discount_name: string;
    /** The id of the discount. */
    discount_id: string;
};
export type Drop = {
    /** The name of the drop. */
    name: string;
    /** The cost of the drop.
    This is a string as it scraped as such and might include non numerical values.
     */
    cost: string;
    /** The URL of the title image. */
    image_url: string;
    /** The style code of the drop. */
    style_code: string;
    /** The release date of the drop. */
    release_date: string;
};
export type CreateDrop = {
    /** The name of the drop. */
    name: string;
    /** The style code of the drop. */
    style_code: string;
    /** The price of the drop. */
    price: string;
    /** The URL of the title image. */
    image_url: string;
    /** The release date of the drop. */
    release_date: string;
};
export type LeaderboardUser = {
    /** The user's avatar hash */
    avatar_hash?: string;
    /** The user's discord tag */
    discord_tag?: string;
    /** The user's discord id */
    discord_id?: string;
    rank: string;
    /** The user's score */
    score: number;
    /** The user's position in the leaderboard */
    position: number;
};
export type LeaderboardResponse = {
    leaderboard: LeaderboardUser[];
    own_placement: LeaderboardUser;
};
export type LeaderboardPricePositionType = "first" | "second" | "third" | "top_five";
export type LeaderboardPrice = {
    /** The price for the position */
    price: string;
    position: LeaderboardPricePositionType;
};
export type ChangeLeaderboardPrice = {
    /** The price for the position */
    price: string;
};
export type IspBlock = {
    /** The name of the Block */
    name: string;
    /** Whether the block is unblocked */
    is_unblocked: boolean;
    /** Whether the block is godlike */
    is_godlike: boolean;
};
export type LeaderboardUser2 = {
    /** The user's avatar url */
    avatar_url?: string;
    /** The user's display name */
    display_name: string;
    /** The user's rank */
    rank: string;
    /** The user's score */
    score: number;
    /** The user's position in the leaderboard */
    position: number;
    /** The award the position would win at the end. */
    awards?: string[];
};
export type LeaderboardPrice2 = {
    position: "first" | "second" | "third" | "top_five";
    price: string;
};
export type HomepageResponseBody = {
    /** The list of all drops in this month. */
    drops: Drop[];
    /** The list of unblocks. */
    blocks: IspBlock[];
    leaderboard: {
        leaderboard: LeaderboardUser2[];
        own_placement: LeaderboardUser2;
    };
    leaderboard_prices: LeaderboardPrice2[];
};
export type ResidentialUsage = {
    delta: number;
    timestamp: string;
};
export type ResidentialUsageSimple = {
    total_available: number;
    total_used: number;
};
export type ProductOverview = {
    /** Combined Usage of residential packages over the last 30 days. */
    residential_usage_30_days: ResidentialUsage[];
    residential_usage_simple: ResidentialUsageSimple;
    active_datacenter_packages: number;
    active_residential_packages: number;
    active_gmail_packages: number;
    active_snkrs_packages: number;
};
export type ResidentialPackageStateType = "active" | "inactive";
export type ResidentialPackage = {
    /** The id of the residential package. */
    id: string;
    /** The id of the user the residential package is assigned to. */
    user_id: string;
    /** The id of the residential plan. */
    residential_plan_id: string;
    /** The expiry date of the residential package. */
    expiry_date: string;
    /** The start date of the residential package. */
    start_date: string;
    state: ResidentialPackageStateType;
    /** The name of the residential package. */
    name: string;
    is_elite: boolean;
    used_quota: number;
    quota: number;
};
export type ResidentialProductPage = {
    residential_usage?: ResidentialUsage[];
    residential_usage_combined?: ResidentialUsageSimple;
    residential_packages: ResidentialPackage[];
};
export type Location = {
    /** The id of the location. */
    id: string;
    /** The label of the location. */
    label: string;
    /** The country code of the location. */
    country_code: string;
};
export type ResidentialProductDetailPage = {
    residential_package: ResidentialPackage;
    locations: Location[];
};
export type SimpleDatacenterPlan = {
    /** The unique id of the datacenter plan. */
    id: string;
    /** The name of the datacenter plan. */
    name: string;
    /** The shopify id of the variant of the datacenter plan. */
    variant_id?: number;
};
export type DatacenterPlanFlag = "godlike" | "order_proxies" | "reminders_enabled" | "for_farmed";
export type CreateDatacenterPlan = {
    /** The name of the datacenter plan. */
    name: string;
    /** The price of the datacenter plan in cents. */
    price: number;
    /** The shopify id of the variant of the datacenter plan. */
    variant_id?: number;
    active: boolean;
    /** The duration of the datacenter plan. */
    duration: string;
    /** The activation time of the datacenter plan. If the activation time > now, the package is not activated and
    its waited for the activation time.
     */
    activation_time: string;
    /** The initial duration of the datacenter plan in seconds. Initial duration is the duration for the first iteration
    of the datacenter package before a renewal is up. An initial duration of 10 days would mean that after assignment
    the package is active for 10 days while renewals add the duration time.
     */
    initial_duration?: string;
    /** The subnet id of the datacenter plan. */
    subnet_id?: string;
    /** The subnet group id of the datacenter plan. */
    subnet_group_id?: string;
    /** The number of proxies in the datacenter plan. */
    proxy_count: number;
    /** The flags of the datacenter plan. */
    flags: DatacenterPlanFlag[];
    /** The duration of the unblock in seconds. */
    unblock_duration?: string;
};
export type BaseDatacenterPlan = {
    /** The name of the datacenter plan. */
    name: string;
    /** The price of the datacenter plan in cents. */
    price: number;
    /** The shopify id of the variant of the datacenter plan. */
    variant_id?: number;
    active: boolean;
    /** The duration of the datacenter plan. */
    duration: string;
    /** The activation time of the datacenter plan. If the activation time > now, the package is not activated and
    its waited for the activation time.
     */
    activation_time: string;
    /** The initial duration of the datacenter plan in seconds. Initial duration is the duration for the first iteration
    of the datacenter package before a renewal is up. An initial duration of 10 days would mean that after assignment
    the package is active for 10 days while renewals add the duration time.
     */
    initial_duration?: string;
    /** The subnet id of the datacenter plan. */
    subnet_id?: string;
    /** The subnet group id of the datacenter plan. */
    subnet_group_id?: string;
    /** The number of proxies in the datacenter plan. */
    proxy_count: number;
    /** The flags of the datacenter plan. */
    flags?: DatacenterPlanFlag[];
    /** The duration of the unblock in seconds. */
    unblock_duration?: string;
};
export type DatacenterPlan = BaseDatacenterPlan & {
    /** The unique id of the datacenter plan. */
    id: string;
};
export type UpdateDatacenterPlan = {
    /** The name of the datacenter plan. */
    name: string;
    /** The price of the datacenter plan in cents. */
    price: number;
    /** The shopify id of the variant of the datacenter plan. */
    variant_id?: number;
    active: boolean;
    /** The duration of the datacenter plan. */
    duration: string;
    /** The activation time of the datacenter plan. If the activation time > now, the package is not activated and
    its waited for the activation time.
     */
    activation_time: string;
    /** The initial duration of the datacenter plan in seconds. Initial duration is the duration for the first iteration
    of the datacenter package before a renewal is up. An initial duration of 10 days would mean that after assignment
    the package is active for 10 days while renewals add the duration time.
     */
    initial_duration?: string;
    /** The subnet id of the datacenter plan. */
    subnet_id?: string;
    /** The subnet group id of the datacenter plan. */
    subnet_group_id?: string;
    /** The number of proxies in the datacenter plan. */
    proxy_count: number;
    /** The flags of the datacenter plan. */
    flags: DatacenterPlanFlag[];
    /** The duration of the unblock in seconds. */
    unblock_duration?: string;
};
export type BaseGmailPlan = {
    /** The name of the plan. */
    name: string;
    /** The shopify id of the plan variant. */
    variant_id: number;
    /** Whether the plan is active. */
    active: boolean;
    /** The amount of gmail addresses. */
    amount: number;
    /** The id of the datacenter plan. */
    datacenter_plan_id?: string;
    /** The price of the plan in USD. */
    price: number;
};
export type GmailPlan = BaseGmailPlan & {
    /** The id of the plan. */
    id: string;
};
export type GetFreeGmailAddressesCountResponseBody = {
    /** The amount of free gmail addresses. */
    normal_count: number;
    /** The amount of free gmail addresses with proxies. */
    proxy_count: number;
};
export type GmailAddress = {
    /** The email address. */
    email: string;
    /** The password. */
    password: string;
    /** The recovery email address. */
    recovery: string;
    /** The secret answer. */
    secret_answer?: string;
    /** The proxy for farmed gmails in the format ip[:port:username:password]. */
    proxy?: string;
};
export type CreatedGmailAddress = {
    id: string;
    /** The email address. */
    email: string;
    /** The password. */
    password: string;
    /** The secret answer. */
    secret_answer?: string;
    /** The recovery email address. */
    recovery: string;
};
export type ResidentialPlanType = "heaven" | "elite";
export type BaseResidentialPlan = {
    /** Name of the plan */
    name: string;
    /** Price of the plan in cents */
    price: number;
    /** shopify ID of the variant */
    variant_id: number;
    /** Whether the plan is active or not */
    active: boolean;
    duration: string;
    /** Quota of the plan in GB */
    quota: number;
    "type": ResidentialPlanType;
};
export type ResidentialPlan = BaseResidentialPlan & {
    /** ID of the plan */
    id: string;
};
export type SnkrsBasePlan = {
    /** The name of the plan. */
    name: string;
    /** The shopify id of the plan variant. */
    variant_id: number;
    /** Whether the plan is active. */
    active: boolean;
    /** The bundle size is used to determine which bundle out of the available ones to choose.
    Bundles have a few different sizes, namely 10 and 25.
     */
    bundle_size?: number;
    /** The amount of accounts in the plan. */
    account_amount?: number;
    /** If the snkrs plan is farmed, the datacenter_plan_id specifies the id of the datacenter plan which holds the proxy counterparts of the snkrs accounts.
     */
    datacenter_plan_id?: string;
};
export type SnkrsPlan = SnkrsBasePlan & {
    /** The id of the plan. */
    id: string;
};
export type CreateSnkrsAccount = {
    account: string;
    proxy?: string;
};
export type SnkrsBundleStats = {
    amount: number;
    free_normal_bundles: number;
    free_farmed_bundles: number;
    used_normal_bundles: number;
    used_farmed_bundles: number;
};
export type SnkrsAccountBundleStats = {
    free_normal_accounts: number;
    free_farmed_accounts: number;
    used_normal_accounts: number;
    used_farmed_accounts: number;
    bundle_stats: SnkrsBundleStats[];
};
export type SnkrsBundle = {
    /** The id of the bundle. */
    id: string;
    /** The size of the bundle. */
    size: number;
};
export type BundleAccount = {
    account: string;
    proxy?: string;
};
export type CreateSnkrsBundle = {
    /** The gmail address of the bundle. */
    gmail: string;
    accounts: BundleAccount[];
};
export type Location2 = {
    /** Country code of the location */
    country_code: string;
    /** Label of the location */
    label: string;
};
export type ServerFlagType = "isp" | "captcha" | "unblocked" | "godlike";
export type BaseServer = {
    /** Server name */
    name: string;
    /** Server short name */
    short_name: string;
    /** Server IP address */
    ip_address: string;
    flags: ServerFlagType[];
};
export type Subnet = {
    id: string;
    name: string;
};
export type Server = BaseServer & {
    /** Server ID */
    id: string;
    /** Server is deletable */
    is_deletable: boolean;
    subnets: Subnet[];
};
export type CreateServerSubnet = {
    /** Subnet name */
    name: string;
    /** Subnet IP range in CIDR notation */
    ip_range_cidr: string;
};
export type CreateServer = {
    /** Server name */
    name: string;
    /** Server short name */
    short_name: string;
    /** Server IP address */
    ip_address: string;
    flags: ServerFlagType[];
    subnets?: CreateServerSubnet[];
};
export type IpWhitelistActivity = {
    ips?: string[];
};
export type UnblockedUsername = {
    /** The username */
    username: string;
    /** Array of unblocked sites */
    sites: string[];
};
export type ServersProxy = {
    ip_address: string;
    username: string;
    password: string;
};
export type BaseSubnetGroup = {
    /** The name of the subnet group. */
    name: string;
};
export type SubnetGroupsSubnet = {
    id: string;
    name: string;
};
export type SubnetGroup = BaseSubnetGroup & {
    /** The unique identifier for the subnet group. */
    id: string;
    /** The list of subnets in the subnet group. */
    subnets: SubnetGroupsSubnet[];
    /** The total number of proxies in the subnet group. */
    total_proxy_count: number;
    /** The number of proxies in the subnet group that are in use. */
    used_proxy_count: number;
    /** Indicates whether the subnet group can be deleted or not (just taking hard constraints into account). */
    deletable: boolean;
    /** Weather the subnet group is used somewhere but could be deleted without causing any primary errors (like breaking constraints). */
    safe_to_delete: boolean;
    reasons_against_deletion?: string[];
    advices_against_deletion?: string[];
};
export type CreateSubnetGroup = BaseSubnetGroup & {
    /** The unique identifiers for the subnets. */
    subnet_ids?: string[];
};
export type UpdateSubnetGroup = CreateSubnetGroup;
export type BaseSubnet = {
    /** Name of the subnet */
    name: string;
    /** ID of the server attached to the subnet */
    server_id: string;
    subnet_group_name?: string;
    /** ID of the subnet group */
    subnet_group_id?: string;
};
export type SubnetDeletableDatacenterPlan = {
    /** ID of the datacenter plan */
    id: string;
    /** Name of the datacenter plan */
    name: string;
};
export type SubnetsSubnet = BaseSubnet & {
    /** ID of the subnet */
    id: string;
    /** Weather the subnet can be deleted */
    deletable: boolean;
    /** Amount of proxies in use */
    amount_of_proxies_in_use: number;
    datacenter_plans?: SubnetDeletableDatacenterPlan[];
    /** Number of free proxies in the subnet */
    free_now: number;
    /** Number of free proxies in the subnet in two days */
    free_in_two_days: number;
    /** Number of free proxies in the subnet in seven days */
    free_in_seven_days: number;
};
export type SubnetFlagsType = "gmail" | "snkrs";
export type CreateSubnetDetails = {
    /** Name of the subnet */
    name: string;
    /** subnet in CIDR notation */
    subnet: string;
};
export type CreateSubnet = {
    /** ID of the server attached to the subnet */
    server_id: string;
    /** ID of the subnet group */
    subnet_group_id?: string;
    flags: SubnetFlagsType[];
    subnets: CreateSubnetDetails[];
};
export type NotificationChannelType = "discord" | "email" | "dashboard";
export type SwapSubnets = {
    subnets_to_swap: string[];
    swap_with_type: "group" | "subnet";
    swap_with: string[];
    in_order?: boolean;
    notification_message: string;
    notification_channels: NotificationChannelType[];
};
export type SubnetMultipleNotificationBody = {
    /** Message of the notification */
    message: string;
    /** Notification channels */
    notification_channels: NotificationChannelType[];
    /** List of subnet IDs */
    subnet_ids: string[];
};
export type NotifySubnetsUsersNotifiedResponse = {
    users_notified: number;
};
export type SearchUserBody = {
    criteria: "email" | "discord_id" | "discord_name" | "proxy_ip";
    criteria_value: string;
};
export type SearchUser = {
    /** The user's unique identifier. */
    id: string;
    /** The email of the user. */
    email: string;
    rank_name: string;
    /** The score of the user. */
    score: number;
    /** Whether the user is active or not. */
    active: boolean;
    /** The stripe customer id of the user. */
    customer_id?: string;
    datacenter_package_id?: string;
};
export type BaseUser = {
    /** The user's unique identifier. */
    id: string;
    /** The email of the user. */
    email: string;
    rank_name: string;
    rank_id: string;
    /** The score of the user. */
    score: number;
    /** Whether the user is active or not. */
    active: boolean;
    /** The stripe customer id of the user. */
    customer_id?: string;
};
export type User = BaseUser & {
    /** The discord tag of the user. */
    discord_tag?: string;
    /** The discord id of the user. */
    discord_id?: string;
    /** The avatar hash of the user. */
    avatar_hash?: string;
    /** The activation url for the user. */
    activation_url?: string;
    /** The last attempt the activation key was used. */
    last_attempt_at?: string;
    /** The number of attempts the activation key has been used. */
    attempt_count?: number;
    /** Whether two factor authentication is enabled or not. */
    two_factor_enabled: boolean;
};
export type UpdateUser = {
    /** The email address of the user. */
    email: string;
    rank_id: string;
    /** The score of the user. */
    score: number;
};
export type UpdateUserPassword = {
    /** The old password of the user. */
    old_password: string;
    /** The new password of the user. */
    new_password: string;
};
export type TwoFactorEnable = {
    /** QR Code to scan */
    qr: string;
    /** Secret to use for verification */
    secret: string;
};
export type TwoFactorConfirm = {
    /** Two Factor Backup Codes */
    backup_codes: string[];
};
export type Payment = {
    id: string;
    user_id: string;
    status: "shopify_success" | "invoice_success" | "invoice_failed" | "subscription_setup" | "subscription_remove";
    link?: string;
    payment_id: string;
    package_type: "datacenter" | "snkrs" | "residential" | "gmail";
    package_id: string;
    package_name: string;
    price: number;
    currency: string;
    created_at: string;
};
export type StripeCustomerPortalSession = {
    url: string;
};
export type IpWhitelist = {
    id: string;
    user_id: string;
    ip_address: string;
    label?: string;
    last_used_at?: string;
};
export type CreateIpWhitelist = {
    ip_address: string;
    label?: string;
};
export type SuccessEntry = {
    id: string;
    image_count: number;
    points?: number;
    created_at: string;
    handled_at?: string;
    reason?: string;
    state: "pending" | "accepted" | "rejected";
};
export type UpdateSuccessEntryBody = {
    action: "accepted" | "rejected";
    points?: number;
    reason?: string;
};
export type DatacenterPackageStateType = "active" | "inactive" | "paused";
export type SubscriptionLoyaltyStepDetail = {
    days_until_end_reached: number;
    percentage_until_end_reached: number;
    date_end: string;
    discount_applied: boolean;
};
export type SubscriptionLoyaltyProgramInfo = {
    start_to_three_months?: SubscriptionLoyaltyStepDetail;
    three_to_five_months?: SubscriptionLoyaltyStepDetail;
    five_to_eight_months?: SubscriptionLoyaltyStepDetail;
};
export type ActiveSubscription = {
    /** The id of the subscription. */
    id: string;
    /** The id of the stripe subscription. */
    stripe_id: string;
    /** The date the subscription started. */
    start_date: string;
    /** The date the next invoice is due. */
    next_invoice_date: string;
    /** The status of the subscription. */
    status: "active" | "canceled" | "pending";
    /** The date the subscription was canceled. */
    canceled_at?: string;
    /** The discount percentage applied to the subscription. */
    discount_percentage_applied?: number;
    loyalty_program_info: SubscriptionLoyaltyProgramInfo;
};
export type Proxy = {
    id: string;
    ip_address: string;
    port: number;
    username: string;
    password: string;
};
export type SimpleDatacenterPackage = {
    /** The id of the datacenter package. */
    id: string;
    /** The id of the user the datacenter package is assigned to. */
    user_id: string;
    /** The id of the datacenter plan. */
    datacenter_plan_id: string;
    /** The expiry date of the datacenter package. */
    expiry_date: string;
    /** The start date of the datacenter package. */
    start_date: string;
    state: DatacenterPackageStateType;
    active_subscription?: ActiveSubscription;
    /** The stripe required action of the datacenter package. This is only set if an invoice fails due to 3D secure
    or similar missing authoirization.
     */
    required_action_stripe?: string;
    /** The end date of the pause. */
    pause_end_date?: string;
    /** The name of the datacenter package. */
    name: string;
    /** The number of proxies the datacenter package has. */
    proxy_count: number;
    reminders_enabled: boolean;
    proxies?: Proxy[];
};
export type CreateDatacenterPackageBody = {
    /** The id of the datacenter plan. */
    datacenter_plan_id: string;
};
export type DatacenterPackageProxyNotification = {
    /** The id of the notification. */
    id: string;
    /** The id of the datacenter package. */
    datacenter_package_id: string;
    /** The date the notification was created. */
    created_at: string;
    /** The message of the notification. */
    message: string;
    /** The ids of the proxies that are affected by the notification. */
    proxy_ids: string[];
};
export type CurrentUnblock = {
    id: string;
    /** The start date of the unblock. */
    start_date: string;
    /** The end date of the unblock. */
    end_date?: string;
    /** The name of the block. */
    block_name: string;
};
export type PastUnblock = {
    /** The start date of the unblock. */
    start_date: string;
    /** The end date of the unblock. */
    end_date: string;
    /** The name of the block. */
    block_name: string;
};
export type PossibleUnblock = {
    /** The id of the unblock. */
    id: string;
    /** The name of the block. */
    block_name: string;
};
export type Unblock = {
    /** The time remaining in full minutes. */
    time_remaining: number;
    current_unblock?: CurrentUnblock;
    past_unblocks: PastUnblock[];
    possible_unblocks: PossibleUnblock[];
};
export type DatacenterPackage = {
    /** The id of the datacenter package. */
    id: string;
    /** The id of the user the datacenter package is assigned to. */
    user_id: string;
    /** The id of the datacenter plan. */
    datacenter_plan_id: string;
    /** The expiry date of the datacenter package. */
    expiry_date: string;
    /** The start date of the datacenter package. */
    start_date: string;
    state: DatacenterPackageStateType;
    active_subscription?: ActiveSubscription;
    /** The stripe required action of the datacenter package. This is only set if an invoice fails due to 3D secure
    or similar missing authorization.
     */
    required_action_stripe?: string;
    /** The end date of the pause. */
    pause_end_date?: string;
    /** The name of the datacenter package. */
    name: string;
    /** The number of proxies the datacenter package has. */
    proxy_count: number;
    reminders_enabled: boolean;
    /** The renewal price of the datacenter package in cents. */
    renewal_price: number;
    /** The time added to the expiry data when the datacenter package is renewed
    in days
     */
    renewal_time_interval: number;
    proxy_notifications?: DatacenterPackageProxyNotification[];
    proxies?: Proxy[];
    allowed_for_custom_unblock: boolean;
    unblock?: Unblock;
};
export type DatacenterPackageReminderToggleBody = {
    /** Whether the datacenter package has a reminder. */
    enabled: boolean;
};
export type DatacenterPackageLogType = "info" | "warning" | "error" | "debug";
export type DatacenterPackageLog = {
    id: string;
    datacenter_package_id: string;
    "type": DatacenterPackageLogType;
    created_at: string;
    message: string;
    action_by_user_id?: string;
};
export type ResetDatacenterPackageCredentialsBody = {
    subnet_address_ids: string[];
};
export type BodyForUnblockingADatacenterPackage = {
    duration_in_minutes?: number;
    block_id: string;
};
export type ExtendDatacenterPackageBlockBody = {
    duration_in_minutes: number;
};
export type DatacenterPackageExtensionBody = {
    /** The id of the discount to apply to the extension. */
    discount_id?: string;
};
export type DatacenterPackageExtensionResponseBody = {
    /** The stripe session id of the extension. */
    session_id: string;
};
export type DatacenterPackageSetupSubscriptionResponseBody = {
    /** The stripe session id. */
    session_id: string;
};
export type BringBackDatacenterPackageProxiesBody = {
    notification_message?: string;
    subnet_ids?: string[];
    subnet_group_ids?: string[];
    new_expiry_date: string;
};
export type BringBackProxiesResponse = {
    amount_of_proxies_already_in_use: number;
    amount_of_packages: number;
};
export type PauseBody = {
    /** The duration in minutes */
    duration: string;
};
export type UpdateDatacenterExpiryBody = {
    /** The new expiry date of the datacenter package. */
    expiry: string;
};
export type CheckoutSession = {
    session_id: string;
    state: "CREATED" | "CHECKED_OUT" | "PROCESSED" | "ERRORED";
    subscription_id?: string;
};
export type SwapDatacenterPackageProxiesBody = {
    subnet_address_ids: string[];
    swap_with_type: "group" | "subnet";
    swap_with: string[];
    in_order: boolean;
};
export type SwapRequest = {
    /** The ID of the swap request. */
    id: string;
    /** The requested proxy swaps. */
    proxies: Proxy[];
    /** The reason for the request. */
    request_reason?: string;
    /** The reason for the denial of the request. */
    deny_reason?: string;
    /** The ranked sites for the new ips. */
    ranking: string[];
    /** Whether the request has been accepted. */
    accepted: boolean;
    /** Whether the request has been denied. */
    denied: boolean;
};
export type SwapRequestStatus = {
    status: "available" | "pending" | "unavailable";
    swap_request?: SwapRequest;
};
export type CreateSwapRequest = {
    /** The proxies that need to be swapped. */
    proxies: Proxy[];
    /** The reason for the swap. */
    reason?: string;
    /** An ordered list of sites, for the new IPs. */
    ranking: string[];
};
export type ResidentialPackage2 = {
    /** ID of the package */
    id: string;
    /** Used quota of the package in bytes (1e9 bytes = 1GB) */
    used_quota: number;
    /** State of the package */
    state: "active" | "inactive";
    /** ID of the plan */
    residential_plan_id: string;
    /** Expiry date of the package */
    expiry_date: string;
    /** Start date of the package */
    start_date: string;
    /** ID of the user */
    user_id: string;
    /** Name of the package */
    name: string;
    /** Quota of the package in GB */
    quota: number;
    /** Type of the plan */
    "type": "elite" | "heaven";
    /** Maximum number of proxies to generate in one go */
    max: number;
};
export type CreateResidentialPackageBody = {
    /** ID of the plan */
    plan_id: string;
};
export type CombinedResidentialStats = {
    timestamp: string;
    /** Usage change of the stats in bytes (1e9 bytes = 1GB) */
    delta: number;
};
export type ResidentialPackagesTotalUsage = {
    /** Total usage of the packages in bytes (1e9 bytes = 1GB) */
    used: number;
    /** Total quota of the packages in bytes (1e9 bytes = 1GB) */
    total: number;
};
export type ResidentialPackageLogType = "info" | "warning" | "error" | "debug";
export type ResidentialPackageLog = {
    id: string;
    residential_package_id: string;
    "type": ResidentialPackageLogType;
    created_at: string;
    message: string;
    action_by_user_id?: string;
};
export type ResidentialProxiesGenerationBody = {
    /** Amount of proxies to generate */
    amount: number;
    /** Type of the proxies to generate */
    "type": "dynamic" | "static";
    /** Location of the proxies to generate */
    location: string;
    /** Type of the proxy pool */
    poolType?: string;
};
export type ResidentialProxiesGenerationResponseBody = string[];
export type UsersGmailGmailAddress = {
    /** The email of the address. */
    email: string;
    /** The password of the address. */
    password: string;
    /** The recovery email of the address. */
    recovery: string;
    /** The proxy of the address. */
    proxy?: string;
    /** The secret answer of the address. */
    secret_answer?: string;
    /** The id of the gmail package. */
    gmail_package_id: string;
};
export type DatacenterPackage2 = {
    /** The id of the datacenter package. */
    id: string;
    /** The id of the user the datacenter package is assigned to. */
    user_id: string;
    /** The id of the datacenter plan. */
    datacenter_plan_id: string;
    /** The expiry date of the datacenter package. */
    expiry_date: string;
    /** The start date of the datacenter package. */
    start_date: string;
    state: "active" | "inactive" | "paused";
    /** The stripe subscription id of the datacenter package. */
    subscription_id?: string;
    /** The name of the datacenter package. */
    name: string;
    /** The number of proxies the datacenter package has. */
    proxy_count: number;
};
export type GmailPackage = {
    /** The id of the package. */
    id: string;
    /** The id of the user. */
    user_id: string;
    /** The id of the gmail plan. */
    gmail_plan_id: string;
    /** The state of the package. */
    state: "active" | "inactive";
    /** The name of the package. */
    name: string;
    /** The amount of gmail addresses. */
    amount: number;
    /** The gmail addresses. */
    addresses: UsersGmailGmailAddress[];
    datacenter_package?: DatacenterPackage2;
};
export type CreateGmailPackage = {
    /** The id of the plan. */
    plan_id: string;
};
export type GmailPackageLogType = "info" | "warning" | "error" | "debug";
export type GmailPackageLog = {
    id: string;
    gmail_package_id: string;
    "type": GmailPackageLogType;
    created_at: string;
    message: string;
    action_by_user_id?: string;
};
export type SnkrsAccount = {
    /** The email of the account. */
    account: string;
    /** The proxy of the account. */
    proxy?: string;
    /** The id of the snkrs package. */
    snkrs_package_id: string;
};
export type UsersSnkrsSnkrsBundle = {
    /** The number of proxies in the bundle. */
    bundle_size: number;
    accounts: SnkrsAccount[];
    /** The email account of the user. */
    email?: string;
};
export type DatacenterPackage3 = {
    /** The id of the datacenter package. */
    id: string;
    /** The id of the user the datacenter package is assigned to. */
    user_id: string;
    /** The id of the datacenter plan. */
    datacenter_plan_id: string;
    /** The expiry date of the datacenter package. */
    expiry_date: string;
    /** The start date of the datacenter package. */
    start_date: string;
    state: "active" | "inactive" | "paused";
    /** The stripe subscription id of the datacenter package. */
    subscription_id?: string;
    /** The name of the datacenter package. */
    name: string;
    /** The number of proxies the datacenter package has. */
    proxy_count: number;
};
export type SnkrsPackage = {
    /** The id of the package. */
    id: string;
    /** The id of the user. */
    user_id: string;
    /** The id of the snkrs plan. */
    snkrs_plan_id: string;
    /** The state of the package. */
    state: "active" | "inactive";
    /** The name of the package. */
    name: string;
    bundle?: UsersSnkrsSnkrsBundle;
    datacenter_package?: DatacenterPackage3;
};
export type CreateSnkrsPackage = {
    /** The id of the plan. */
    plan_id: string;
};
export type SnkrsPackageLogType = "info" | "warning" | "error" | "debug";
export type SnkrsPackageLog = {
    id: string;
    snkrs_package_id: string;
    "type": SnkrsPackageLogType;
    created_at: string;
    message: string;
    action_by_user_id?: string;
};
export type Rank = {
    id: string;
    name: string;
};
export type Issue = {
    id: string;
    category: "order_queue";
    category_id: string;
    state: "unresolved" | "resolved" | "ignored";
    created_at: string;
    description: string;
    suggestion_to_solve: string;
    solved_at?: string;
    solved_by_user_id?: string;
};
export type SuccessEntry1 = {
    id: string;
    state: "pending" | "accepted" | "rejected";
    user_id: string;
    discord_tag?: string;
    created_at: string;
    mentions_twitter: boolean;
    image_urls: string[];
};
export type MonitoringLog = {
    id: string;
    created_at: string;
    datacenter_package_id: string;
    mean: number;
    target: string;
    user_id: string;
};
export type Popup = {
    /** The popup id. */
    id: string;
    /** The popup title. */
    title: string;
    /** The popup content. */
    content: string;
    /** The popup created date. */
    created_at: string;
};
export type CreatePopup = {
    /** The popup title. */
    title: string;
    /** The popup content. */
    content: string;
};
export type Proxy1 = {
    /** The unique id of the proxy. */
    id: string;
    ip_address: string;
    port: number;
    /** The username for proxy authentication. */
    username: string;
    /** The password for proxy authentication. */
    password: string;
};
export type SwapRequest1 = {
    /** The unique id of the swap request. */
    id: string;
    /** The unique id of the user who requested the swap. */
    user_id: string;
    /** The unique id of the datacenter package. */
    datacenter_package_id: string;
    /** The requested proxy swaps. */
    proxies: Proxy1[];
    /** The reason for the request. */
    request_reason?: string;
    /** The reason for the denial of the request. */
    deny_reason?: string;
    /** The ranked sites for the new ips. */
    ranking: string[];
    /** Whether or not this swap request has been accepted. */
    accepted: boolean;
    /** Whether or not this swap request has been denied. */
    denied: boolean;
    /** Whether or not this swap request is completed. */
    completed: boolean;
};
export type SwapRequestAccept = {
    /** Whether to swap with a subnet or subnet group. */
    swap_type: "subnet" | "group";
    /** What subnet or subnet group to swap with. */
    swap_with: string[];
    /** Whether to swap in order. */
    in_order: boolean;
};
export type SwapRequestDeny = {
    /** The reason for denying the swap request. */
    reason?: string;
};
/**
 * Handles incoming webhooks from Stripe
 */
export function stripeHandler(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText("/stripe/handler", {
        ...opts,
        method: "POST"
    });
}
/**
 * Get the currently logged in user.
 */
export function getAuthMe(extended: boolean, { admin }: {
    admin?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UserReturnedByTheMeEndpoint | MeResponseExtended;
    } | {
        status: 401;
        data: Error;
    } | {
        status: 403;
        data: Error;
    }>(`/auth/me${QS.query(QS.explode({
        extended,
        admin
    }))}`, {
        ...opts
    });
}
/**
 * Login with given credentials
 */
export function postAuthLogin(loginBody?: LoginBody, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UserReturnedByTheMeEndpoint;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 418;
    } | {
        status: 429;
        data: Error;
    }>("/auth/login", oazapfts.json({
        ...opts,
        method: "POST",
        body: loginBody
    }));
}
/**
 * Activate the automatically created account on the first shopify purchase.
 */
export function postAuthShopActivate(shopActivateBody?: ShopActivateBody, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 429;
        data: Error;
    }>("/auth/activation/shop", oazapfts.json({
        ...opts,
        method: "POST",
        body: shopActivateBody
    }));
}
/**
 * Activate account after registering normally and not being registered by shopify.
 */
export function postAuthRegisterActivate(registerActivateBody?: RegisterActivateBody, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 429;
        data: Error;
    }>("/auth/activation/register", oazapfts.json({
        ...opts,
        method: "POST",
        body: registerActivateBody
    }));
}
/**
 * Register a new user
 */
export function postAuthRegister(registerBody?: RegisterBody, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: RegisterResponse;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 409;
        data: Error;
    } | {
        status: 429;
        data: Error;
    }>("/auth/register", oazapfts.json({
        ...opts,
        method: "POST",
        body: registerBody
    }));
}
/**
 * Request to resend the activation key after registering.
 */
export function postAuthActivationResend(resendRegistrationKeyBody?: ResendRegistrationKeyBody, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ResendRegistrationKeyResponseBody;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 429;
        data: Error;
    }>("/auth/activation/resend", oazapfts.json({
        ...opts,
        method: "POST",
        body: resendRegistrationKeyBody
    }));
}
/**
 * Request a password reset.
 */
export function authPasswordRequestReset(requestPasswordResetBody?: RequestPasswordResetBody, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 400;
        data: Error;
    }>("/auth/password/request_reset", oazapfts.json({
        ...opts,
        method: "POST",
        body: requestPasswordResetBody
    }));
}
/**
 * Reset the password to a new password.
 */
export function authPostPasswordReset(resetPasswordBody?: ResetPasswordBody, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 429;
        data: Error;
    }>("/auth/password/reset", oazapfts.json({
        ...opts,
        method: "POST",
        body: resetPasswordBody
    }));
}
/**
 * Logout
 */
export function authPostLogout(all: boolean, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/auth/logout${QS.query(QS.explode({
        all
    }))}`, {
        ...opts,
        method: "POST"
    });
}
/**
 * Login with the linked discord account.
 */
export function authDiscordLogin(code: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UserReturnedByTheMeEndpoint;
    } | {
        status: 400;
        data: Error;
    }>(`/auth/discord/login${QS.query(QS.explode({
        code
    }))}`, {
        ...opts,
        method: "POST"
    });
}
/**
 * Get the status of all ISP blocks (e.g. Yeezy, Footsites)
 */
export function getBlocks(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Block[];
    }>("/blocks/", {
        ...opts
    });
}
/**
 * Disable block (=unblock) the specific block for a given amount of time.
 */
export function postBlocksUnblock(blockId: string, unblockBody?: UnblockBody, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Block;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 404;
        data: Error;
    }>(`/blocks/${encodeURIComponent(blockId)}/unblock`, oazapfts.json({
        ...opts,
        method: "POST",
        body: unblockBody
    }));
}
/**
 * Enable block for the specific block.
 */
export function postBlocksBlock(blockId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Block;
    } | {
        status: 404;
    }>(`/blocks/${encodeURIComponent(blockId)}/block`, {
        ...opts,
        method: "POST"
    });
}
/**
 * Get all discounts
 */
export function getDiscounts(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Discount[];
    }>("/discounts/", {
        ...opts
    });
}
/**
 * Create a new discount
 */
export function postDiscounts(createDiscount?: CreateDiscount, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: Discount;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 409;
        data: Error;
    }>("/discounts/", oazapfts.json({
        ...opts,
        method: "POST",
        body: createDiscount
    }));
}
/**
 * Update a specific discount
 */
export function patchDiscount(id: string, updatableDiscount?: UpdatableDiscount, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Discount;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 404;
        data: Error;
    } | {
        status: 409;
        data: Error;
    }>(`/discounts/discounts/${encodeURIComponent(id)}`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: updatableDiscount
    }));
}
/**
 * Delete a specific discount
 */
export function deleteDiscount(id: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 404;
        data: Error;
    }>(`/discounts/discounts/${encodeURIComponent(id)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Check if a discount is valid and calculate the price difference
 */
export function applyAndCalculateDiscount(calculateDiscountBody?: CalculateDiscountBody, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: CalculatedDiscount;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 404;
        data: Error;
    } | {
        status: 429;
        data: Error;
    }>("/discounts/apply-and-calculate", oazapfts.json({
        ...opts,
        method: "POST",
        body: calculateDiscountBody
    }));
}
/**
 * Get all upcoming drops within a specific month.
 */
export function getDrops(year: number, month: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Drop[];
    } | {
        status: 400;
        data: Error;
    }>(`/drops/${QS.query(QS.explode({
        year,
        month
    }))}`, {
        ...opts
    });
}
/**
 * Create a new drop
 */
export function postDrops(createDrop?: CreateDrop, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: Drop;
    } | {
        status: 400;
        data: Error;
    }>("/drops/", oazapfts.json({
        ...opts,
        method: "POST",
        body: createDrop
    }));
}
/**
 * Get all manually added drops
 */
export function getDropsManual(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Drop[];
    }>("/drops/manual", {
        ...opts
    });
}
/**
 * Delete a specific manual drop
 */
export function deleteDrop(id: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 404;
        data: Error;
    }>(`/drops/${encodeURIComponent(id)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Get the top 5 users by score
 */
export function getLeaderboard(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: LeaderboardResponse;
    }>("/leaderboard/", {
        ...opts
    });
}
/**
 * Clear the score of all users.
 */
export function patchLeaderboardGlobalScore(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText("/leaderboard/global-score", {
        ...opts,
        method: "PATCH"
    });
}
/**
 * Get the prices for the top 5 leaderboard users.
 */
export function getLeaderboardPrices(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: LeaderboardPrice[];
    }>("/leaderboard/prices", {
        ...opts
    });
}
/**
 * Update a specific leaderboard price
 */
export function patchLeaderboardPrice(position: string, changeLeaderboardPrice?: ChangeLeaderboardPrice, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: LeaderboardPrice;
    } | {
        status: 400;
        data: Error;
    }>(`/leaderboard/prices/${encodeURIComponent(position)}`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: changeLeaderboardPrice
    }));
}
/**
 * Get homepage
 */
export function getHomepage(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: HomepageResponseBody;
    }>("/pages/home", {
        ...opts
    });
}
/**
 * Get product overview
 */
export function getProductOverview(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ProductOverview;
    }>("/pages/products/overview", {
        ...opts
    });
}
export function getResidentialProducts(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ResidentialProductPage;
    }>("/pages/products/residential", {
        ...opts
    });
}
export function pagesGetResidentialProducts(residentialPackageId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ResidentialProductDetailPage;
    }>(`/pages/products/residential/${encodeURIComponent(residentialPackageId)}`, {
        ...opts
    });
}
/**
 * Get all datacenter plans
 */
export function getPlansDatacenter({ onlyActive }: {
    onlyActive?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: SimpleDatacenterPlan[];
    }>(`/plans/datacenter/${QS.query(QS.explode({
        "only-active": onlyActive
    }))}`, {
        ...opts
    });
}
/**
 * Create a new datacenter plan
 */
export function createDatacenterPlan(createDatacenterPlan?: CreateDatacenterPlan, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: DatacenterPlan;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 409;
        data: Error;
    }>("/plans/datacenter/", oazapfts.json({
        ...opts,
        method: "POST",
        body: createDatacenterPlan
    }));
}
/**
 * Get all datacenter plans
 */
export function getPlansDatacenterPlan(planId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: DatacenterPlan;
    } | {
        status: 404;
        data: Error;
    }>(`/plans/datacenter/${encodeURIComponent(planId)}`, {
        ...opts
    });
}
/**
 * Update a datacenter plan
 */
export function patchPlansDatacenterPlan(planId: string, updateDatacenterPlan?: UpdateDatacenterPlan, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: DatacenterPlan;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 404;
        data: Error;
    } | {
        status: 409;
        data: Error;
    }>(`/plans/datacenter/${encodeURIComponent(planId)}`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: updateDatacenterPlan
    }));
}
/**
 * Disable a datacenter plan.
 */
export function deletePlansDatacenterPlan(planId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 404;
        data: Error;
    }>(`/plans/datacenter/${encodeURIComponent(planId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Get all gmail plans
 */
export function getPlansGmail(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: GmailPlan[];
    }>("/plans/gmail/", {
        ...opts
    });
}
/**
 * Create a gmail plan
 */
export function postPlansGmail(baseGmailPlan?: BaseGmailPlan, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: GmailPlan;
    } | {
        status: 400;
        data: Error;
    }>("/plans/gmail/", oazapfts.json({
        ...opts,
        method: "POST",
        body: baseGmailPlan
    }));
}
/**
 * Get a specific gmail plan
 */
export function getPlansGmailPlan(planId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: GmailPlan;
    } | {
        status: 404;
        data: Error;
    }>(`/plans/gmail/${encodeURIComponent(planId)}`, {
        ...opts
    });
}
/**
 * Update a specific gmail plan
 */
export function patchPlansGmailPlan(planId: string, baseGmailPlan?: BaseGmailPlan, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: GmailPlan;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 404;
        data: Error;
    }>(`/plans/gmail/${encodeURIComponent(planId)}`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: baseGmailPlan
    }));
}
/**
 * Gets the count of free gmail addresses
 */
export function getPlansGmailAddresses(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: GetFreeGmailAddressesCountResponseBody;
    }>("/plans/gmail/addresses", {
        ...opts
    });
}
/**
 * Create new gmail addresses
 */
export function postPlansGmailAddresses(body?: GmailAddress[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: CreatedGmailAddress[];
    } | {
        status: 400;
        data: Error;
    } | {
        status: 409;
        data: Error;
    }>("/plans/gmail/addresses", oazapfts.json({
        ...opts,
        method: "POST",
        body
    }));
}
/**
 * Get all residential plans
 */
export function getPlansResidential(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ResidentialPlan[];
    }>("/plans/residential/", {
        ...opts
    });
}
/**
 * Create a new residential plan
 */
export function postPlansResidential(baseResidentialPlan?: BaseResidentialPlan, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: ResidentialPlan;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 409;
        data: Error;
    }>("/plans/residential/", oazapfts.json({
        ...opts,
        method: "POST",
        body: baseResidentialPlan
    }));
}
/**
 * Get a specific residential plan
 */
export function getPlansResidentialPlan(planId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ResidentialPlan;
    } | {
        status: 404;
        data: Error;
    }>(`/plans/residential/${encodeURIComponent(planId)}`, {
        ...opts
    });
}
/**
 * Deactivate a specific residential plan.
 */
export function deletePlansResidentialPlan(planId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 404;
        data: Error;
    }>(`/plans/residential/${encodeURIComponent(planId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Update a residential plan
 */
export function patchPlansResidentialPlan(planId: string, baseResidentialPlan?: BaseResidentialPlan, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ResidentialPlan;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 404;
        data: Error;
    } | {
        status: 409;
        data: Error;
    }>(`/plans/residential/${encodeURIComponent(planId)}`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: baseResidentialPlan
    }));
}
/**
 * Get all SNKRS plans
 */
export function getPlansSnkrs(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: SnkrsPlan[];
    }>("/plans/snkrs/", {
        ...opts
    });
}
/**
 * Create a snkrs plan
 */
export function postPlansSnkrs(snkrsBasePlan?: SnkrsBasePlan, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: SnkrsPlan;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 409;
        data: Error;
    }>("/plans/snkrs/", oazapfts.json({
        ...opts,
        method: "POST",
        body: snkrsBasePlan
    }));
}
/**
 * Get a specific snkrs plan
 */
export function getPlansSnkrsPlan(planId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: SnkrsPlan;
    } | {
        status: 404;
        data: Error;
    }>(`/plans/snkrs/${encodeURIComponent(planId)}`, {
        ...opts
    });
}
/**
 * Update a specific snkrs plan
 */
export function patchPlansSnkrsPlan(planId: string, snkrsBasePlan?: SnkrsBasePlan, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: SnkrsPlan;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 404;
        data: Error;
    } | {
        status: 409;
        data: Error;
    }>(`/plans/snkrs/${encodeURIComponent(planId)}`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: snkrsBasePlan
    }));
}
/**
 * Disable a specific snkrs plan
 */
export function deletePlansSnkrsPlan(planId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 404;
        data: Error;
    }>(`/plans/snkrs/${encodeURIComponent(planId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Create a snkrs account
 */
export function postPlansSnkrsAccounts(body?: CreateSnkrsAccount[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 409;
        data: Error;
    }>("/plans/snkrs/accounts", oazapfts.json({
        ...opts,
        method: "POST",
        body
    }));
}
/**
 * Get all snkrs account bundle stats
 */
export function getPlansSnkrsAccountBundleStats(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: SnkrsAccountBundleStats;
    }>("/plans/snkrs/account-bundle-stats", {
        ...opts
    });
}
/**
 * Get all unassigned snkrs bundles
 */
export function getPlansSnkrsBundles(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: SnkrsBundle[];
    }>("/plans/snkrs/bundles", {
        ...opts
    });
}
/**
 * Create a snkrs bundle
 */
export function postPlansSnkrsBundles(body?: CreateSnkrsBundle[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: SnkrsBundle[];
    } | {
        status: 400;
        data: Error;
    } | {
        status: 409;
        data: Error;
    }>("/plans/snkrs/bundles", oazapfts.json({
        ...opts,
        method: "POST",
        body
    }));
}
/**
 * Get all possible bundle sizes
 */
export function getPlansSnkrsBundlesPossibleSizes(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: number[];
    }>("/plans/snkrs/bundles/possible-sizes", {
        ...opts
    });
}
/**
 * Get all possible locations for generating residential proxies
 */
export function getResidentialLocations(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Location2[];
    }>("/residential/locations", {
        ...opts
    });
}
/**
 * Get all servers
 */
export function getServers(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Server[];
    }>("/servers/", {
        ...opts
    });
}
/**
 * Create a new server
 */
export function postServer(createServer?: CreateServer, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: Server;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 409;
        data: Error;
    }>("/servers/", oazapfts.json({
        ...opts,
        method: "POST",
        body: createServer
    }));
}
/**
 * Get a specific server
 */
export function getServer(serverId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Server;
    } | {
        status: 404;
        data: Error;
    }>(`/servers/${encodeURIComponent(serverId)}`, {
        ...opts
    });
}
/**
 * Update a specific server
 */
export function patchServer(serverId: string, baseServer?: BaseServer, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Server;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 404;
        data: Error;
    } | {
        status: 409;
        data: Error;
    }>(`/servers/${encodeURIComponent(serverId)}`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: baseServer
    }));
}
/**
 * Delete a specific server
 */
export function deleteServer(serverId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 404;
        data: Error;
    }>(`/servers/${encodeURIComponent(serverId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Get all IPs whitelisted for a specific server
 */
export function getServerIpWhitelist(serverId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: string[];
    } | {
        status: 404;
        data: Error;
    }>(`/servers/${encodeURIComponent(serverId)}/ip_whitelist`, {
        ...opts
    });
}
/**
 * Send body of ip addresses which were used to update on the dashboard.
 */
export function postServerIpWhitelist(serverId: string, ipWhitelistActivity?: IpWhitelistActivity, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 404;
        data: Error;
    }>(`/servers/${encodeURIComponent(serverId)}/ip_whitelist`, oazapfts.json({
        ...opts,
        method: "POST",
        body: ipWhitelistActivity
    }));
}
export function getServerUnblockedUsernames(serverId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UnblockedUsername[];
    } | {
        status: 404;
        data: Error;
    }>(`/servers/${encodeURIComponent(serverId)}/unblocked_usernames`, {
        ...opts
    });
}
/**
 * Retrieve a full list of all proxies which are assigned for a specific server.
 *
 */
export function getServerProxies(serverId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ServersProxy[];
    } | {
        status: 404;
        data: Error;
    }>(`/servers/${encodeURIComponent(serverId)}/proxies`, {
        ...opts
    });
}
export function getServerBlocks(serverId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: string[];
    } | {
        status: 404;
        data: Error;
    }>(`/servers/${encodeURIComponent(serverId)}/blocks`, {
        ...opts
    });
}
/**
 * Handle incoming shopify webhooks
 */
export function postShopifyHandler(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText("/shopify/handler", {
        ...opts,
        method: "POST"
    });
}
/**
 * Get all subnet groups
 */
export function getSubnetGroups(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: SubnetGroup[];
    }>("/subnet_groups/", {
        ...opts
    });
}
/**
 * Create a new subnet group
 */
export function createSubnetGroup(createSubnetGroup?: CreateSubnetGroup, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: SubnetGroup;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 409;
        data: Error;
    }>("/subnet_groups/", oazapfts.json({
        ...opts,
        method: "POST",
        body: createSubnetGroup
    }));
}
/**
 * Get a specific subnet group
 */
export function getSubnetGroup(groupId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: SubnetGroup;
    } | {
        status: 404;
        data: Error;
    }>(`/subnet_groups/${encodeURIComponent(groupId)}`, {
        ...opts
    });
}
export function patchSubnetGroup(groupId: string, updateSubnetGroup?: UpdateSubnetGroup, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: SubnetGroup;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 404;
        data: Error;
    } | {
        status: 409;
        data: Error;
    }>(`/subnet_groups/${encodeURIComponent(groupId)}`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: updateSubnetGroup
    }));
}
/**
 * Delete a specific subnet group
 */
export function deleteSubnetGroup(groupId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 404;
        data: Error;
    } | {
        status: 409;
        data: Error;
    }>(`/subnet_groups/${encodeURIComponent(groupId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Get all subnets
 */
export function getSubnets(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: SubnetsSubnet[];
    }>("/subnets/", {
        ...opts
    });
}
/**
 * Create a new subnet
 */
export function postSubnet(createSubnet?: CreateSubnet, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: SubnetsSubnet[];
    } | {
        status: 400;
        data: Error;
    } | {
        status: 409;
        data: Error;
    }>("/subnets/", oazapfts.json({
        ...opts,
        method: "POST",
        body: createSubnet
    }));
}
/**
 * Swap multiple subnets
 */
export function postSubnetsSwap(swapSubnets?: SwapSubnets, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 409;
        data: Error;
    }>("/subnets/swap", oazapfts.json({
        ...opts,
        method: "POST",
        body: swapSubnets
    }));
}
/**
 * Get a specific subnet
 */
export function getSubnet(subnetId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: SubnetsSubnet;
    } | {
        status: 404;
        data: Error;
    }>(`/subnets/${encodeURIComponent(subnetId)}`, {
        ...opts
    });
}
/**
 * Delete a specific subnet
 */
export function deleteSubnet(subnetId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 404;
        data: Error;
    } | {
        status: 409;
        data: Error;
    }>(`/subnets/${encodeURIComponent(subnetId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Update a specific subnet
 */
export function updateSubnet(subnetId: string, baseSubnet?: BaseSubnet, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: SubnetsSubnet;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 404;
        data: Error;
    } | {
        status: 409;
        data: Error;
    }>(`/subnets/${encodeURIComponent(subnetId)}`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: baseSubnet
    }));
}
/**
 * Send a notification to all users which have proxies in the given subnets.
 */
export function notifySubnetsUsers(subnetMultipleNotificationBody?: SubnetMultipleNotificationBody, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: NotifySubnetsUsersNotifiedResponse;
    } | {
        status: 400;
        data: Error;
    }>("/subnets/notify", oazapfts.json({
        ...opts,
        method: "POST",
        body: subnetMultipleNotificationBody
    }));
}
/**
 * Search for users by specific criteria
 */
export function getUsersBySearch(searchUserBody?: SearchUserBody, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: SearchUser[];
    } | {
        status: 400;
        data: Error;
    }>("/users/search", oazapfts.json({
        ...opts,
        method: "POST",
        body: searchUserBody
    }));
}
/**
 * Get a specific user
 */
export function getUser(userId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: User;
    } | {
        status: 404;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}`, {
        ...opts
    });
}
/**
 * Update a specific user
 */
export function updateUser(userId: string, updateUser?: UpdateUser, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: User;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 403;
        data: Error;
    } | {
        status: 404;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: updateUser
    }));
}
/**
 * Update a users' password
 */
export function updateUserPassword(userId: string, updateUserPassword?: UpdateUserPassword, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: User;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 404;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}/password`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: updateUserPassword
    }));
}
/**
 * Enable two factor auth
 */
export function enableTwoFactorAuth(userId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: TwoFactorEnable;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 404;
        data: Error;
    } | {
        status: 409;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}/two_factor_auth`, {
        ...opts,
        method: "POST"
    });
}
/**
 * Confirm two factor auth
 */
export function confirmTwoFactorAuth(userId: string, xTwoFactorAuthCode: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: TwoFactorConfirm;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 403;
        data: Error;
    } | {
        status: 404;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}/two_factor_auth`, {
        ...opts,
        method: "PATCH",
        headers: {
            ...opts && opts.headers,
            "X-TwoFactorAuth-Code": xTwoFactorAuthCode
        }
    });
}
/**
 * Disable two factor auth
 */
export function disableTwoFactorAuth(userId: string, xTwoFactorAuthCode: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 403;
        data: Error;
    } | {
        status: 404;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}/two_factor_auth`, {
        ...opts,
        method: "DELETE",
        headers: {
            ...opts && opts.headers,
            "X-TwoFactorAuth-Code": xTwoFactorAuthCode
        }
    });
}
/**
 * Regenerate two factor backup codes
 */
export function regenerateTwoFactorAuthBackupCodes(userId: string, xTwoFactorAuthCode: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: TwoFactorConfirm;
    } | {
        status: 403;
        data: Error;
    } | {
        status: 404;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}/two_factor_auth/backup_codes`, {
        ...opts,
        method: "PATCH",
        headers: {
            ...opts && opts.headers,
            "X-TwoFactorAuth-Code": xTwoFactorAuthCode
        }
    });
}
/**
 * Get a users' payments
 */
export function getUserPayments(userId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Payment[];
    } | {
        status: 404;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}/payments`, {
        ...opts
    });
}
/**
 * Get a users' stripe customer portal session
 */
export function getUserStripeCustomerPortalSession(userId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: StripeCustomerPortalSession;
    } | {
        status: 404;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}/stripe_customer_portal_session`, {
        ...opts
    });
}
/**
 * Create a users' discord
 */
export function createUserDiscord(userId: string, code: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: User;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 404;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}/discord${QS.query(QS.explode({
        code
    }))}`, {
        ...opts,
        method: "POST"
    });
}
/**
 * Delete a users' discord
 */
export function deleteUserDiscord(userId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 404;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}/discord`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Get a users' ip whitelist
 */
export function getUserIpWhitelist(userId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: IpWhitelist[];
    } | {
        status: 404;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}/ip_whitelist`, {
        ...opts
    });
}
/**
 * Create a users' ip whitelist
 */
export function createUserIpWhitelist(userId: string, createIpWhitelist: CreateIpWhitelist, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: IpWhitelist;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 404;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}/ip_whitelist`, oazapfts.json({
        ...opts,
        method: "POST",
        body: createIpWhitelist
    }));
}
/**
 * Delete a users' ip whitelist
 */
export function deleteUserIpWhitelist(userId: string, ipWhitelistId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 404;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}/ip_whitelist/${encodeURIComponent(ipWhitelistId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Get a users' success entries
 */
export function getUserSuccessEntries(userId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: SuccessEntry[];
    } | {
        status: 404;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}/success_entries`, {
        ...opts
    });
}
export function updateUserSuccessEntry(userId: string, id: string, updateSuccessEntryBody: UpdateSuccessEntryBody, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 401;
        data: Error;
    } | {
        status: 404;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}/success_entries/${encodeURIComponent(id)}`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: updateSuccessEntryBody
    }));
}
/**
 * Get datacenter packages from user
 */
export function getUserPackagesDatacenter(userId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: SimpleDatacenterPackage[];
    }>(`/users/${encodeURIComponent(userId)}/packages/datacenter`, {
        ...opts
    });
}
export function postUserPackagesDatacenter(userId: string, createDatacenterPackageBody?: CreateDatacenterPackageBody, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: SimpleDatacenterPackage;
    } | {
        status: 400;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}/packages/datacenter`, oazapfts.json({
        ...opts,
        method: "POST",
        body: createDatacenterPackageBody
    }));
}
/**
 * Get specific datacenter package
 */
export function getUsersPackagesDatacenterPackage(userId: string, datacenterPackageId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: DatacenterPackage;
    } | {
        status: 404;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}/packages/datacenter/${encodeURIComponent(datacenterPackageId)}`, {
        ...opts
    });
}
/**
 * Deactivate datacenter package
 */
export function deactivateDatacenterPackage(userId: string, datacenterPackageId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: SimpleDatacenterPackage;
    } | {
        status: 404;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}/packages/datacenter/${encodeURIComponent(datacenterPackageId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Redeem loyalty discount
 */
export function redeemLoyaltyDiscount(userId: string, datacenterPackageId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 404;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}/packages/datacenter/${encodeURIComponent(datacenterPackageId)}/redeem-loyalty-discount`, {
        ...opts,
        method: "POST"
    });
}
/**
 * Delete proxy notification
 */
export function deleteProxyNotification(userId: string, datacenterPackageId: string, notificationId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 404;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}/packages/datacenter/${encodeURIComponent(datacenterPackageId)}/proxy-notifications/${encodeURIComponent(notificationId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Enable if disabled, disable if enabled the renewal reminders for a datacenter package
 */
export function updateDatacenterPackageReminder(userId: string, datacenterPackageId: string, datacenterPackageReminderToggleBody?: DatacenterPackageReminderToggleBody, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: DatacenterPackage;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 404;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}/packages/datacenter/${encodeURIComponent(datacenterPackageId)}/reminder`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: datacenterPackageReminderToggleBody
    }));
}
export function getLogsByDatacenterPackage(userId: string, datacenterPackageId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: DatacenterPackageLog[];
    }>(`/users/${encodeURIComponent(userId)}/packages/datacenter/${encodeURIComponent(datacenterPackageId)}/logs`, {
        ...opts
    });
}
export function resetDatacenterPackageCredentials(userId: string, datacenterPackageId: string, resetDatacenterPackageCredentialsBody?: ResetDatacenterPackageCredentialsBody, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: DatacenterPackage;
    } | {
        status: 400;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}/packages/datacenter/${encodeURIComponent(datacenterPackageId)}/credentials_reset`, oazapfts.json({
        ...opts,
        method: "POST",
        body: resetDatacenterPackageCredentialsBody
    }));
}
export function postDatacenterPackageUnblock(userId: string, datacenterPackageId: string, unblockDatacenterPackageBody?: BodyForUnblockingADatacenterPackage, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: DatacenterPackage;
    } | {
        status: 400;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}/packages/datacenter/${encodeURIComponent(datacenterPackageId)}/block`, oazapfts.json({
        ...opts,
        method: "POST",
        body: unblockDatacenterPackageBody
    }));
}
export function patchDatacenterPackageBlock(userId: string, datacenterPackageId: string, customUnblockId: string, extendDatacenterPackageBlockBody?: ExtendDatacenterPackageBlockBody, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: DatacenterPackage;
    } | {
        status: 400;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}/packages/datacenter/${encodeURIComponent(datacenterPackageId)}/block/${encodeURIComponent(customUnblockId)}`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: extendDatacenterPackageBlockBody
    }));
}
export function blockDatacenterPackage(userId: string, datacenterPackageId: string, customUnblockId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: DatacenterPackage;
    } | {
        status: 409;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}/packages/datacenter/${encodeURIComponent(datacenterPackageId)}/block/${encodeURIComponent(customUnblockId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Extend the datacenter package
 */
export function extendDatacenterPackage(userId: string, datacenterPackageId: string, datacenterPackageExtensionBody?: DatacenterPackageExtensionBody, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: DatacenterPackageExtensionResponseBody;
    } | {
        status: 400;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}/packages/datacenter/${encodeURIComponent(datacenterPackageId)}/extend`, oazapfts.json({
        ...opts,
        method: "POST",
        body: datacenterPackageExtensionBody
    }));
}
/**
 * Subscribe to the datacenter package
 */
export function addSubscriptionToDatacenterPackage(userId: string, datacenterPackageId: string, datacenterPackageExtensionBody?: DatacenterPackageExtensionBody, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: DatacenterPackageSetupSubscriptionResponseBody;
    } | {
        status: 400;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}/packages/datacenter/${encodeURIComponent(datacenterPackageId)}/subscription`, oazapfts.json({
        ...opts,
        method: "POST",
        body: datacenterPackageExtensionBody
    }));
}
/**
 * Remove a subscription from the datacenter package
 */
export function removeSubscriptionFromDatacenterPackage(userId: string, datacenterPackageId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: DatacenterPackage;
    }>(`/users/${encodeURIComponent(userId)}/packages/datacenter/${encodeURIComponent(datacenterPackageId)}/subscription`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Bring back proxies for a datacenter package
 */
export function bringBackDatacenterPackageProxies(userId: string, datacenterPackageId: string, bringBackDatacenterPackageProxiesBody?: BringBackDatacenterPackageProxiesBody, { complex }: {
    complex?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: DatacenterPackage;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 409;
        data: BringBackProxiesResponse;
    }>(`/users/${encodeURIComponent(userId)}/packages/datacenter/${encodeURIComponent(datacenterPackageId)}/bring-back-proxies${QS.query(QS.explode({
        complex
    }))}`, oazapfts.json({
        ...opts,
        method: "POST",
        body: bringBackDatacenterPackageProxiesBody
    }));
}
/**
 * Pause the datacenter package
 */
export function pauseDatacenterPackage(userId: string, datacenterPackageId: string, pauseBody?: PauseBody, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: DatacenterPackage;
    } | {
        status: 400;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}/packages/datacenter/${encodeURIComponent(datacenterPackageId)}/pause`, oazapfts.json({
        ...opts,
        method: "POST",
        body: pauseBody
    }));
}
/**
 * Unpauses the datacenter package
 */
export function unpauseDatacenterPackage(userId: string, datacenterPackageId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: DatacenterPackage;
    }>(`/users/${encodeURIComponent(userId)}/packages/datacenter/${encodeURIComponent(datacenterPackageId)}/pause`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Update expiry date of the datacenter package
 */
export function updateDatacenterPackageExpiry(userId: string, datacenterPackageId: string, updateDatacenterExpiryBody?: UpdateDatacenterExpiryBody, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: DatacenterPackage;
    } | {
        status: 400;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}/packages/datacenter/${encodeURIComponent(datacenterPackageId)}/expiry`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: updateDatacenterExpiryBody
    }));
}
export function getDatacenterPackageCheckoutSession(userId: string, datacenterPackageId: string, sessionId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: CheckoutSession;
    } | {
        status: 404;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}/packages/datacenter/${encodeURIComponent(datacenterPackageId)}/checkout_sessions/${encodeURIComponent(sessionId)}`, {
        ...opts
    });
}
export function swapDatacenterPackageProxies(userId: string, datacenterPackageId: string, swapDatacenterPackageProxiesBody?: SwapDatacenterPackageProxiesBody, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Proxy[];
    } | {
        status: 400;
        data: Error;
    } | {
        status: 404;
        data: Error;
    } | {
        status: 409;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}/packages/datacenter/${encodeURIComponent(datacenterPackageId)}/swap_proxies`, oazapfts.json({
        ...opts,
        method: "POST",
        body: swapDatacenterPackageProxiesBody
    }));
}
export function getDatacenterPackageSwapRequestStatus(userId: string, datacenterPackageId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: SwapRequestStatus;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 401;
        data: Error;
    } | {
        status: 404;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}/packages/datacenter/${encodeURIComponent(datacenterPackageId)}/swap_requests`, {
        ...opts
    });
}
export function createDatacenterPackageSwapRequest(userId: string, datacenterPackageId: string, createSwapRequest?: CreateSwapRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: SwapRequest;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 401;
        data: Error;
    } | {
        status: 404;
        data: Error;
    } | {
        status: 409;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}/packages/datacenter/${encodeURIComponent(datacenterPackageId)}/swap_requests`, oazapfts.json({
        ...opts,
        method: "POST",
        body: createSwapRequest
    }));
}
export function cancelDatacenterPackageSwapRequest(userId: string, datacenterPackageId: string, swapRequestId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 401;
        data: Error;
    } | {
        status: 404;
        data: Error;
    } | {
        status: 409;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}/packages/datacenter/${encodeURIComponent(datacenterPackageId)}/swap_requests/${encodeURIComponent(swapRequestId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Get all residential packages for a user
 */
export function getResidentialPackagesByUser(userId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ResidentialPackage2[];
    }>(`/users/${encodeURIComponent(userId)}/packages/residential`, {
        ...opts
    });
}
export function createResidentialPackage(userId: string, createResidentialPackageBody?: CreateResidentialPackageBody, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: ResidentialPackage2;
    } | {
        status: 400;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}/packages/residential`, oazapfts.json({
        ...opts,
        method: "POST",
        body: createResidentialPackageBody
    }));
}
/**
 * Get a residential package
 */
export function getResidentialPackage(userId: string, residentialPackageId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ResidentialPackage2;
    }>(`/users/${encodeURIComponent(userId)}/packages/residential/${encodeURIComponent(residentialPackageId)}`, {
        ...opts
    });
}
/**
 * Gets the detailed stats for all the residential packages over the last 30 days.
 */
export function getCombinedResidentialStats(userId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: CombinedResidentialStats[];
    }>(`/users/${encodeURIComponent(userId)}/packages/residential/stats-combined`, {
        ...opts
    });
}
/**
 * Get usage of all residential packages
 */
export function getResidentialPackagesTotalUsageByUser(userId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ResidentialPackagesTotalUsage;
    }>(`/users/${encodeURIComponent(userId)}/packages/residential/usage`, {
        ...opts
    });
}
/**
 * Get logs for a residential package
 */
export function getResidentialPackageLogs(userId: string, residentialPackageId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ResidentialPackageLog[];
    }>(`/users/${encodeURIComponent(userId)}/packages/residential/${encodeURIComponent(residentialPackageId)}/logs`, {
        ...opts
    });
}
/**
 * Generates proxies for the residential package
 */
export function generateResidentialProxies(userId: string, residentialPackageId: string, residentialProxiesGenerationBody?: ResidentialProxiesGenerationBody, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ResidentialProxiesGenerationResponseBody;
    } | {
        status: 400;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}/packages/residential/${encodeURIComponent(residentialPackageId)}/proxies`, oazapfts.json({
        ...opts,
        method: "POST",
        body: residentialProxiesGenerationBody
    }));
}
/**
 * Deactivates a residential package
 */
export function deactivateResidentialPackage(userId: string, residentialPackageId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ResidentialPackage2;
    }>(`/users/${encodeURIComponent(userId)}/packages/residential/${encodeURIComponent(residentialPackageId)}/deactivate`, {
        ...opts,
        method: "POST"
    });
}
/**
 * Get all gmail packages
 */
export function getGmailPackagesByUser(userId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: GmailPackage[];
    }>(`/users/${encodeURIComponent(userId)}/packages/gmail`, {
        ...opts
    });
}
/**
 * Create a new gmail package
 */
export function createGmailPackage(userId: string, createGmailPackage?: CreateGmailPackage, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: GmailPackage;
    } | {
        status: 400;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}/packages/gmail`, oazapfts.json({
        ...opts,
        method: "POST",
        body: createGmailPackage
    }));
}
/**
 * Get gmail package
 */
export function getGmailPackageByUser(userId: string, gmailPackageId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: GmailPackage;
    }>(`/users/${encodeURIComponent(userId)}/packages/gmail/${encodeURIComponent(gmailPackageId)}`, {
        ...opts
    });
}
/**
 * Get logs for a gmail package
 */
export function getGmailPackageLogsByGmailPackage(userId: string, gmailPackageId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: GmailPackageLog[];
    }>(`/users/${encodeURIComponent(userId)}/packages/gmail/${encodeURIComponent(gmailPackageId)}/logs`, {
        ...opts
    });
}
/**
 * Get all snkrs packages
 */
export function getSnkrsPackagesByUser(userId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: SnkrsPackage[];
    }>(`/users/${encodeURIComponent(userId)}/packages/snkrs`, {
        ...opts
    });
}
/**
 * Create a new snkrs package
 */
export function createSnkrsPackage(userId: string, createSnkrsPackage?: CreateSnkrsPackage, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: SnkrsPackage;
    } | {
        status: 400;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}/packages/snkrs`, oazapfts.json({
        ...opts,
        method: "POST",
        body: createSnkrsPackage
    }));
}
/**
 * Get snkrs package
 */
export function getSnkrsPackageByUser(userId: string, snkrsPackageId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: SnkrsPackage;
    }>(`/users/${encodeURIComponent(userId)}/packages/snkrs/${encodeURIComponent(snkrsPackageId)}`, {
        ...opts
    });
}
/**
 * Get logs for a snkrs package
 */
export function getSnkrsPackageLogsBySnkrsPackage(userId: string, snkrsPackageId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: SnkrsPackageLog[];
    }>(`/users/${encodeURIComponent(userId)}/packages/snkrs/${encodeURIComponent(snkrsPackageId)}/logs`, {
        ...opts
    });
}
export function markAllNotificationsAsSeen(userId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 404;
        data: Error;
    } | {
        status: 409;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}/notifications/mark-all-as-seen`, {
        ...opts,
        method: "POST"
    });
}
export function markNotificationAsSeen(userId: string, notificationId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 404;
        data: Error;
    } | {
        status: 409;
        data: Error;
    }>(`/users/${encodeURIComponent(userId)}/notifications/${encodeURIComponent(notificationId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Get all ranks
 */
export function getRanks(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Rank[];
    }>("/ranks/", {
        ...opts
    });
}
/**
 * Get all issues
 */
export function getIssues({ page }: {
    page?: number;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Issue[];
    }>(`/issues/issues${QS.query(QS.explode({
        page
    }))}`, {
        ...opts
    });
}
/**
 * Update issue
 */
export function resolveIssue(issueId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Issue;
    } | {
        status: 404;
        data: Error;
    }>(`/issues/issues/${encodeURIComponent(issueId)}`, {
        ...opts,
        method: "POST"
    });
}
/**
 * Delete issue
 */
export function ignoreIssue(issueId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Issue;
    } | {
        status: 404;
        data: Error;
    }>(`/issues/issues/${encodeURIComponent(issueId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Get all pending success entries
 */
export function getPendingSuccessEntries(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: SuccessEntry1[];
    } | {
        status: 401;
        data: Error;
    }>("/success-entries/pending", {
        ...opts
    });
}
/**
 * Get all logs
 */
export function getLogs($from: string, to: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: MonitoringLog[];
    } | {
        status: 400;
        data: Error;
    }>(`/monitoring-logs/${QS.query(QS.explode({
        "from": $from,
        to
    }))}`, {
        ...opts
    });
}
/**
 * Get popups
 */
export function getPopups(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Popup[];
    } | {
        status: 401;
        data: Error;
    }>("/popups/", {
        ...opts
    });
}
/**
 * Create popup
 */
export function createPopup(createPopup?: CreatePopup, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Popup;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 401;
        data: Error;
    }>("/popups/", oazapfts.json({
        ...opts,
        method: "POST",
        body: createPopup
    }));
}
/**
 * Get popup
 */
export function getPopup(id: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Popup;
    } | {
        status: 401;
        data: Error;
    } | {
        status: 404;
        data: Error;
    }>(`/popups/${encodeURIComponent(id)}`, {
        ...opts
    });
}
/**
 * Update popup
 */
export function updatePopup(id: string, createPopup?: CreatePopup, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Popup;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 401;
        data: Error;
    } | {
        status: 404;
        data: Error;
    }>(`/popups/${encodeURIComponent(id)}`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: createPopup
    }));
}
/**
 * Delete popup
 */
export function deletePopup(id: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 401;
        data: Error;
    } | {
        status: 404;
        data: Error;
    }>(`/popups/${encodeURIComponent(id)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Get latest popup
 */
export function getLatestPopup(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Popup;
    } | {
        status: 401;
        data: Error;
    }>("/popups/latest", {
        ...opts
    });
}
/**
 * Mark popup as read
 */
export function markPopupAsRead(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 401;
        data: Error;
    }>("/popups/latest", {
        ...opts,
        method: "PATCH"
    });
}
/**
 * Get all swap requests
 */
export function getAllSwapRequests(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: SwapRequest1[];
    }>("/swap-requests/", {
        ...opts
    });
}
/**
 * Get swap request
 */
export function getSwapRequest(id: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: SwapRequest1;
    } | {
        status: 401;
        data: Error;
    } | {
        status: 404;
        data: Error;
    }>(`/swap-requests/${encodeURIComponent(id)}`, {
        ...opts
    });
}
/**
 * Accept a swap request.
 */
export function acceptSwapRequest(id: string, swapRequestAccept?: SwapRequestAccept, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: SwapRequest1;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 401;
        data: Error;
    } | {
        status: 404;
        data: Error;
    }>(`/swap-requests/${encodeURIComponent(id)}/accept`, oazapfts.json({
        ...opts,
        method: "POST",
        body: swapRequestAccept
    }));
}
/**
 * Deny a swap request.
 */
export function denySwapRequest(id: string, swapRequestDeny?: SwapRequestDeny, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: SwapRequest1;
    } | {
        status: 400;
        data: Error;
    } | {
        status: 401;
        data: Error;
    } | {
        status: 404;
        data: Error;
    }>(`/swap-requests/${encodeURIComponent(id)}/deny`, oazapfts.json({
        ...opts,
        method: "POST",
        body: swapRequestDeny
    }));
}
